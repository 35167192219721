import React, { useState, useEffect, useCallback } from 'react'
import ItemsCarousel from 'react-items-carousel'
import styled from '@emotion/styled'
import ImageSlides from '../ImageSlides/ImageSlides/index.tsx'
import ReactImageVideoLightbox from 'react-image-video-lightbox'
import { ReactComponent as SlideLeft } from '../../assets/slideLeft.svg'
import { ReactComponent as SlideRight } from '../../assets/slideRight.svg'
import VideoSvg from '../../assets/video.svg'
import classes from './ImagesContainer.module.css'

const Image = styled.div`
  height: 70px;
  width: 67px;
  display: inline-block;
  padding: 0 5px;
  border-radius: 8px;
  background-size: cover;
  background-image: ${props => (props.url ? `url(${props.url})` : '')};
`

const ImagesContainer = ({ imageUrls = [], onClick }) => {
  const [activeItemIndex, changeActiveItem] = useState(0)
  const [photos, setPhotos] = useState([])
  const [fullWidth, setFullwidth] = useState(false)
  const [imageIndex, setImageIndex] = useState(0)

  const handleUserKeyPress = useCallback(event => {
    const { key, keyCode } = event

    if (keyCode === 27) {
      setFullwidth(false)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress)

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress)
    }
  }, [handleUserKeyPress])

  const handleScrollLeft = () => {
    if (activeItemIndex > 0) {
      changeActiveItem(activeItemIndex - 1)
    }
  }

  const handleScrollRight = () => {
    if (activeItemIndex + 4 < imageUrls.length) {
      changeActiveItem(activeItemIndex + 1)
    }
  }

  const openFullwidth = index => {
    setFullwidth(true)
    setImageIndex(index + 1)
  }

  useEffect(() => {
    if (imageUrls.length) {
      imageUrls.forEach(photo => {
        setPhotos(oldArray => [
          ...oldArray,
          `${process.env.REACT_APP_BASEURL}/uploads/${
            photo.photo ? photo.photo.path : photo.video.path
          }`,
        ])
      })
    }
  }, [])

  return (
    <div>
      {/* {fullWidth ? (
        <ReactImageVideoLightbox
          data={photos}
          startIndex={imageIndex}
          showResourceCount={false}
          onCloseCallback={() => setFullwidth(false)}
        />
      ) : null} */}
      {fullWidth && (
        <div
          style={{
            zIndex: 10000000,
            position: 'fixed',
            right: 40,
            top: 40,
            cursor: 'pointer',
          }}
          className={classes.close__block}
          onClick={() => setFullwidth(false)}
        >
          <img src={require('../../assets/close-line.svg')} />
        </div>
      )}
      <ImageSlides
        index={imageIndex}
        tapClose={true}
        onClose={() => setFullwidth(false)}
        images={photos}
        isOpen={fullWidth}
        showPageButton
      />
      <div
        style={{
          marginBottom: '20px',
          padding: `10px ${10}px`,
        }}
      >
        <ItemsCarousel
          className={classes.carousel}
          numberOfCards={5}
          gutter={4}
          showSlither={true}
          leftChevron={<SlideLeft />}
          rightChevron={<SlideRight />}
          firstAndLastGutter={false}
          freeScrolling={true}
          chevronWidth={40}
          activeItemIndex={activeItemIndex}
          outsideChevron={true}
          requestToChangeActive={changeActiveItem}
        >
          {imageUrls.map((url, index) => (
            <div className={classes.carousel}>
              <Image
                key={index}
                url={
                  url.video
                    ? VideoSvg
                    : `${process.env.REACT_APP_BASEURL}/uploads/${
                        url.photo ? url.photo.path : url.video.path
                      }`
                }
                onClick={() => openFullwidth(index)}
              />
            </div>
          ))}
        </ItemsCarousel>
      </div>
    </div>
  )
}

export default ImagesContainer
