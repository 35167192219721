import styled from '@emotion/styled'

export const Wrapper = styled.div`
    width: 100%;
    height: 100vh;
    background: #E5E5E5;
`

export const CodeWrapper = styled.div`
    max-width: 780px;
    background: #FFFFFF;
    padding: 50px;
    margin: 0 auto;
    margin-top: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);
    @media (max-width: 650px) {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 0;
      padding: 0;
    }
`
export const Title = styled.h3`
    
`
export const CodeContent = styled.div`
    
`
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
`;
export const ConfirmButton = styled.button`
  width: 100%;
  background: #772af3;
  border-radius: 30px;
  color: #ffffff;
  padding: 15px 24px 15px 24px;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none
  }
`;
