import React, { useState, useEffect } from 'react'
import {
    CodeWrapper,
    CodeContent,
    Title,
    ButtonWrapper,
    ConfirmButton,
    Wrapper, Price, Number,OrderNumber
} from './ConfirmationOrder.styles'
import ReactCodeInput from 'react-code-input'
import './codeInput.css'

const ConfirmationOrder = () => {
    const [send,setSend] = useState(false)

    return (
                <Wrapper>
                    <CodeWrapper>
                        <Title>Подтверждение согласования <OrderNumber>ЗН №45-45667</OrderNumber>  </Title>
                        <Price>на сумму 47 291.30 ₽</Price>
                        <Number>Код будет отправлен на номер  +7 (900) ***-**-45</Number>
                        <CodeContent>
                            <ReactCodeInput
                                id="codeInput"
                                type="number"
                                fields={4}

                            />
                            <ButtonWrapper>
                                <ConfirmButton type="submit" disabled={send} onClick={() => setSend(true)}>
                                    Получить код для согласования
                                </ConfirmButton>
                            </ButtonWrapper>
                        </CodeContent>
                    </CodeWrapper>
                </Wrapper>
    )
}

export default ConfirmationOrder;
