import { useState } from 'react'
import {
  MODAL_TYPE_PAY_CREDIT,
  MODAL_TYPE_PAY_ONLINE,
  MODAL_TYPE_DIAGNOSTICS, MODAL_TYPE_CONFIRM_ORDER,
} from '../utils/constants'

const useModal = () => {
  const [visible, setVisible] = useState(false)
  const [visiblePayOnline, setVisiblePayOnline] = useState(false)
  const [visibleModalDiagnostics, setVisibleModalDiagnostics] = useState(false)

  function toggle(type) {
    document.documentElement.scrollTop = 0
    if (type === MODAL_TYPE_PAY_CREDIT) {
      setVisible(!visible)
    }
    if (type === MODAL_TYPE_CONFIRM_ORDER) {
      setVisible(!visible)
    }
    if (type === MODAL_TYPE_PAY_ONLINE) {
      setVisiblePayOnline(!visiblePayOnline)
    }
    if (type === MODAL_TYPE_DIAGNOSTICS) {
      setVisibleModalDiagnostics(!visibleModalDiagnostics)
    }
  }
  return { toggle, visible, visiblePayOnline, visibleModalDiagnostics }
}

export default useModal
