import styled from '@emotion/styled'

export const MainPageWrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-size: cover;
    overflow: hidden;
    @media screen and (max-width: 992px) {
        flex-direction: column;
        align-items: center;
  }
`
export const MainPageText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 150px;
    margin-bottom: 150px;
    flex: 1;
    @media screen and (max-width: 992px) {
        align-items: center;
        justify-content: center;
        margin-left: 0;
        margin-bottom: 0;
        max-width: 80%;
  }
`
export const MainPageImg = styled.div`
display: flex;
flex-direction: row;
align-items: center;
flex: 1;
align-self: flex-end;
  @media (max-width: 576px) {
    padding: 10px;
    padding-top: 30px;
  }
`

export const TizerContacts = styled.div`
  margin-top: 53px;
`

export const Contact = styled.div`
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 20px;
  line-height: 23px;
  text-align: left;
  margin-bottom: 20px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
`;
export const ConfirmButton = styled.a`
  width: 72%;
  background: #8371f4;
  border-radius: 30px;
  color: #FFFFFF;
  padding: 15px 24px 15px 24px;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;