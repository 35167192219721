import React, { useMemo } from "react";
import ReactDOM from "react-dom";
import classes from "./ModalPay.module.css";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  MODAL_TYPE_PAY_ONLINE,
  STATUS_PAY_SBERBANK,
  STATUS_PAY_SBP,
  STATUS_PAY_TINKOFF,
} from "../../utils/constants";

const ModalPayOnline = ({
  visible,
  toggle,
  pay,
  loading,
  loadingSberbank,
  loadingSBP,
  sbpUrl,
  dealerResultCompany,
}) => {
  return visible
    ? ReactDOM.createPortal(
        <div className={classes.modal}>
          <div className={classes.modal__pop} role="dialog" aria-modal="true">
            {!loading && (
              <button
                className={classes.closeIcon}
                type="button"
                onClick={() => toggle(MODAL_TYPE_PAY_ONLINE)}
              />
            )}
            <div className={classes.img_logo}></div>
            <image
              src={require("../../assets/hand.png")}
              alt="svg"
              style={{ width: "100%" }}
            />
            <div className={classes.modal__title}>Оплата онлайн</div>
            <div className={classes.modal__desc}>{sbpUrl? 'Вы будете перенаправлены на внешний платежный сервис' :'Выберите способ оплаты'}</div>
            <div className={classes.buttons__wrapper}>
              {sbpUrl ? (
                <Button
                  disabled={loadingSBP}
                  onClick={() => pay(STATUS_PAY_SBP)}
                  variant="contained"
                  className={classes.sbp}
                  startIcon={
                    loadingSBP ? (
                      <CircularProgress size={10} />
                    ) : (
                      <image
                        src={require("../../assets/sbp-logo.svg")}
                        alt="svg"
                        className={classes.sbp_logo}
                      />
                    )
                  }
                >
                  Оплатить через СБП
                </Button>
              ) : (
                <>
                  <Button
                    disabled={loadingSberbank}
                    onClick={() => pay(STATUS_PAY_SBERBANK)}
                    variant="contained"
                    className={classes.sberbank}
                    startIcon={
                      loadingSberbank && <CircularProgress size={10} />
                    }
                  >
                    Сбербанк
                  </Button>
                  <Button
                    disabled={loading}
                    onClick={() => pay(STATUS_PAY_TINKOFF)}
                    variant="contained"
                    className={classes.tinkoff}
                    startIcon={loading && <CircularProgress size={10} />}
                  >
                    Тинькофф
                  </Button>
                </>
              )}
            </div>
          </div>
          <div className={classes.modal__overlay}></div>
        </div>,
        document.body
      )
    : null;
};

export default ModalPayOnline;
