import React, { useState} from "react";
import styled from "@emotion/styled";
import {AGREEMENT_LINK, PRIVACY_LINK, BASE_URL} from "../../utils/constants";
import defaultImage from '../../assets/tizer-logo-big.png'

const Footer = styled.footer`
  width: 100%;
  background-color: ${(props) => (props.color ? props.color : "#F8F8FF")};
  -webkit-print-color-adjust: exact;
  border-top: 1px dashed #b9b9b9;
  padding-bottom: 20px;
`;
const Circle = styled.div`
  background: #C7C7C7;
  border-radius: 50%;
  height: 4px;
  width: 4px;
  @media (max-width: 600px) {
    display: none;
  }
`;
const Logo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 30px 133px 0px 133px;
  @media (max-width: 650px) {
    padding: 30px 15px 0px 15px;
  }
`;
const Contacts = styled.div`
  display: flex;
  flex-direction: column;
`;
const FooterInfo = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 133px 0px 133px;
  @media (max-width: 700px) {
    padding: 30px 15px 0px 15px;
   flex-direction: column;
  }
`;
const Agreement = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: 600px) {
    align-items: flex-start;
    padding-bottom: 24px;
  }
`;
const Link = styled.a`
  color: #772AF3;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-decoration: none;
  white-space: nowrap;

  &:last-child {
    margin-top: 12px;
  }

  @media (max-width: 600px) {
    &:first-child {
      margin-top: 40px;
    }
  }

`;
const Info = styled.div`
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
`;

const Salon = styled.div`
  line-height: 18px;
  font-weight: ${(props) => (props.fontWeight ? "bold" : "normal")};
  font-style: ${(props) => (props.fontStyle ? "italic" : "normal")};
  font-size: ${(props) => `${props.fontSize}px`};
  font-family: ${(props) => props.fontFamily};
  text-decoration: ${(props) => (props.textDecoration ? "underline" : "none")};
  color: ${(props) => props.color};
  width: 40%;
  padding: 30px 133px 0px 133px;
  @media (max-width: 650px) {
    padding: 30px 15px 0px 15px;
  }
`;
const Label = styled.span`
  font-weight: ${(props) => (props.fontWeight ? "bold" : 300)};
  font-style: ${(props) => (props.fontStyle ? "italic" : "normal")};
  font-size: 12px;
  text-decoration: ${(props) => (props.textDecoration ? "underline" : "none")};
  color: ${(props) => props.color};
  margin-left: 5px;
`;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const Title = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  text-decoration: none;
  color: #999999;
  white-space: nowrap;
  padding-right: 2px;
`;

const ImageContainer = styled.img`
  max-width: ${(props) => props.maxSize}%;
  object-fit: contain;
`;
const defaultStyles = {
    footer_dealer_color: "#999999",
};

export const OrderFooter = ({
    dealer,
    styles = defaultStyles,
    companyLogo,
    email,
    phone,
    fax,
    legalName,
    actualAddress,
    legalAddress,
    requisites,
    companyLegalName,
    isFullOrderLegalNamePrimary,
                                diagnosticsTemplate = false
}) => {
    const [image,setImage] = useState(null)
    const contacts = {
        "Электронная почта":email,
        "Телефон":phone,
        "Телефон/Факс":fax,
        "ИНН/ОГРНИП":requisites,
        "Фактический адрес":actualAddress,
        "Юридический адрес":legalAddress
    }
    return (
        <Footer color={styles.footer_background_color}>
            <Logo>
                {companyLogo && (
                    <ImageContainer
                        maxSize={styles.logo_max_size || "25"}
                        src={image || `${BASE_URL}/uploads/${companyLogo.path}`}
                        onError={() => setImage(defaultImage)}
                    />
                )}
                {styles.tizerLogo && (
                    <ImageContainer
                        maxSize={"25"}
                        src={
                            require("../../assets/tizer-logo-big.png")
                        }
                    />
                )}
            </Logo>
            <Salon
                color={styles.footer_dealer_color}
                fontFamily={styles.footer_dealer_fontFamily}
                fontSize={styles.footer_dealer_fontSize}
                fontStyle={styles.footer_dealer_fontStyle}
                fontWeight={styles.footer_dealer_fontWeight}
                textDecoration={styles.footer_dealer_textDecoration}
            >
                {isFullOrderLegalNamePrimary && companyLegalName || legalName ||  dealer?.name}
            </Salon>
                <FooterInfo>
                    {!(isFullOrderLegalNamePrimary && companyLegalName) && (<Contacts>
                        {Object.entries(contacts).map((contact,index) => {
                            if(contact[1] !== null){
                                return (
                                    <Info key={index}>
                                        <Title>
                                            {contact[0]}:
                                        </Title>
                                        <Label
                                            color={styles.footer_contacts_color}
                                            fontFamily={styles.footer_contacts_fontFamily}
                                            fontSize={styles.footer_contacts_fontSize}
                                            fontStyle={styles.footer_contacts_fontStyle}
                                            fontWeight={styles.footer_contacts_fontWeight}
                                            textDecoration={styles.footer_contacts_textDecoration}
                                        >
                                            {contact[1]}
                                        </Label>
                                    </Info>
                                )
                            }
                        })}
                    </Contacts>)}
                    <Agreement>
                        <Link href={AGREEMENT_LINK} target="_blank" >
                            Пользовательское соглашение
                        </Link>
                        <Link href={PRIVACY_LINK} target="_blank" >
                            Политика конфиденциальности
                        </Link>
                    </Agreement>
                </FooterInfo>
        </Footer>
    );
};

