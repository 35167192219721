import styled from '@emotion/styled'

export const LayoutWrapper = styled.div`
  display: flex;
  width: 100%;
  max-height: 100%;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  @media (max-width: 576px) {
  }
`