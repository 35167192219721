import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const dash = keyframes`
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
`

const color = keyframes`
  0% {
    stroke: #772af3;
  }
  40% {
    stroke: #772af3;
  }
  66% {
    stroke: #772af3;
  }
  80%, 90% {
    stroke: #772af3;
  }
`

const Wrapper = styled.div`
  position: relative;
  width: 300px;
  height: 300px;
  position: absolute;
  top: calc(50vh - 58px);
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
`

const SVG = styled.svg`
  animation: ${rotate} 2s linear infinite;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
`

const Circle = styled.circle`
  stroke-dasharray: 150, 200;
  stroke-dashoffset: -10;
  animation: ${dash} 1.5s ease-in-out infinite, ${color} 6s ease-in-out infinite;
  stroke-linecap: round;
`

const Loader = () => (
  <Wrapper>
    <SVG viewBox='25 25 50 50'>
      <Circle
        cx='50'
        cy='50'
        r='20'
        fill='none'
        stroke='#772af3'
        stroke-width='2'
      />
    </SVG>
  </Wrapper>
)

export default Loader
