import styled from "@emotion/styled";

export const Wrapper = styled.div`
  max-width: 680px;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  margin-top: 80px;
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 650px) {
    margin-top: 0;
    margin-bottom: 0;
    padding-right: 10px;
    padding-left: 10px;
  }
`
export const Content = styled.div`
  width: 100%;
  background: #FFFFFF;
  font-size: 17px;
  line-height: 27px;
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const Header = styled.div`
  width: 100%;
  background: #F8F8FF;
  padding: 30px 40px;
  font-weight: 600;
  font-size: 22px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px dashed #B9B9B9;
`;
export const Background = styled.div`
  background: #E5E5E5;
`;
export const Title = styled.h3`
  padding: 10px 0;
  font-size: 15px;
`;

export const Line = styled.p`
  margin: 5px 0;
  font-size: 15px;
  & > a {
    color: #772af3;
    text-decoration: none;
}
`;