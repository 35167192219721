import React, {useEffect, useMemo, useState} from "react";
import ReactDOM from "react-dom";
import classes from "./ModalConfirm.module.css";
import {MODAL_TYPE_CONFIRM_ORDER,} from "../../utils/constants";
import ReactCodeInput from "react-code-input-clone";
import {getMaskedNumber} from "../../utils/helpers";
import {
    Title,
    Price,
    Number,
    CodeContent,
    ConfirmButton,
    CodeWrapper,
    OrderNumber,
    ButtonWrapper,
    ErrorBlock,
    Modal,
    ModalPop,
    Overlay,
    Text,
    Phone
} from './ModalConfirm.styles'
import './codeInput.css'
export const ModalConfirm = (
    {
        visible,
        toggle,
        isLoading,
        price,
        orderNumber,
        customer,
        handleCheckCode,
        error,
        setError,
        currentCurrency
    }
    ) => {
    const [code,setCode] = useState('')
    const [validCode, setValidCode] = useState(false)

    useEffect(()=>{
        document.addEventListener('keypress',  handlerKey)
        return () => document.removeEventListener('keypress',handlerKey)
    })

    const handlerKey = (e) =>{
        if (e.which === 13) {
            if(validate(code)){
                clickHandler()
            }
        }
    }

    const validate = (code) =>{
        if (!code) {
            setValidCode(false)
            return false
        } else if (code.length < 4) {
            setValidCode(false)
            return false
        } else {
            setValidCode(true)
            return true
        }
    }

    const clickHandler = () =>{
        handleCheckCode(code)
    }

    const handleCode = (code) =>{

        setError('')
        setCode(code)
        validate(code)
    }
    return visible
        ? ReactDOM.createPortal(
            <Modal>
                <ModalPop role="dialog" aria-modal="true">
                    {!isLoading && (
                        <button
                            className={classes.closeIcon}
                            type="button"
                            onClick={() => toggle(MODAL_TYPE_CONFIRM_ORDER)}
                        />
                    )}
                    <Title>Подтверждение согласования <OrderNumber>ЗН № {orderNumber}</OrderNumber>  </Title>
                    <Price>на сумму {price} {currentCurrency}</Price>
                    <Number>Ранее вам было отправлено смс со ссылкой на страницу Заказ-наряда и кодом, на номер +{getMaskedNumber(customer.phone)} </Number>

                    <CodeContent>
                        <ReactCodeInput
                            id="codeInput"
                            type="number"
                            className={classes.input__number}
                            fields={4}
                            value={code}
                            isValid={validCode}
                            onChange={handleCode}
                            autoComplete="one-time-code"
                        />
                        <Text>Введите 4-х значный код из смс и нажмите кнопку ниже</Text>
                        <ButtonWrapper>
                            <ConfirmButton type="submit" disabled={isLoading || !validCode}  onClick={clickHandler}>
                                Подтвердить согласование
                            </ConfirmButton>
                        </ButtonWrapper>
                        {error && (
                            <ErrorBlock>
                                <img src={require("../../assets/error.svg")} />
                                Неверный код согласования
                            </ErrorBlock>
                        )}
                    </CodeContent>
                </ModalPop>
                <Overlay />
            </Modal>,
            document.body
        )
        : null;
};

