import React, { useState, useMemo } from 'react'
import styled from '@emotion/styled'
import doneIcon from '../../assets/done_icon.svg'
import alertIcon from '../../assets/iconAlert.svg'
import errorIcon from '../../assets/iconError.svg'
import {
  TYPE_TEXT,
  TYPE_BOOL,
  TYPE_DATE,
  TYPE_PERCENT,
  TYPE_LEVEL,
  TYPE_INT,
} from '../../utils/constants'

const ImageBlock = styled.div`
  display: flex;
  margin-left: -7px;
  padding-right: 7px;
`
const Image = styled.img`
  display: block;
  object-fit: cover;
`

export const ImageStatusBlock = ({ type, value }) => {
  const MIN_PERCENT = useMemo(() => value <= 33 && value < 34)
  const MID_PERCENT = useMemo(() => value >= 34 && value < 67)
  const MAX_PERCENT = useMemo(() => value >= 67)

  const imageStatus = (type, value) => {
    const doneStatus = doneIcon
    const alertStatus = alertIcon
    const errorStatus = errorIcon

    switch (type) {
      // Text
      case TYPE_TEXT:
        switch (value) {
          default:
            return doneStatus
        }
      // Boolean
      case TYPE_BOOL:
        switch (value) {
          case 'true':
            return doneStatus
          case 'false':
            return errorStatus
          default:
            return doneStatus
        }
      // Integer
      case TYPE_INT:
        return
      //  Level
      case TYPE_LEVEL:
        switch (value) {
          case 'good':
            return doneStatus
          case 'normal':
            return alertStatus
          case 'bad':
            return errorStatus
          case null:
            return errorStatus
          default:
            return doneStatus
        }
      //  Percent
      case TYPE_PERCENT:
        switch (true) {
          case MIN_PERCENT:
            return errorStatus
          case MID_PERCENT:
            return alertStatus
          case MAX_PERCENT:
            return doneStatus
          default:
            return doneStatus
        }
      // Date string
      case TYPE_DATE:
        return doneStatus
      default:
        return doneStatus
    }
  }
  return (
    <>
      <ImageBlock>
        <Image src={imageStatus(type, value)} alt="status" />
      </ImageBlock>
    </>
  )
}
