export const titleStyles = (styles, key) => {
  switch (key) {
    case 'mainTitle':
      return {
        ...styles,
        color: styles.main_action_title_color,
        fontFamily: styles.main_action_title_fontFamily,
        fontSize: styles.main_action_title_fontSize,
        fontStyle: styles.main_action_title_fontStyle,
        fontWeight: styles.main_action_title_fontWeight,
        textDecoration: styles.main_action_title_textDecoration,
      }
    case 'recommendation':
      return {
        ...styles,
        color: styles.main_recommendation_title_color,
        fontFamily: styles.main_recommendation_title_fontFamily,
        fontSize: styles.main_recommendation_title_fontSize,
        fontStyle: styles.main_recommendation_title_fontStyle,
        fontWeight: styles.main_recommendation_title_fontWeight,
        textDecoration: styles.main_recommendation_title_textDecoration,
      }
  }
}
