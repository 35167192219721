import React from "react";
import styled from "@emotion/styled";
import Checkbox from "../Checkbox/index";

const Item = styled.li`
  border-radius: 3px;
  padding: 6px 10px;
  list-style-type: none;
`;

const Label = styled.label`
  display: flex;
`;

const Information = styled.div`
  display: flex;
  width: 100%;
  margin-left: 8px;
  justify-content: space-between;
  align-items: center;
`;

const Span = styled.span`
  font-weight: ${(props) => (props.fontWeight ? "bold" : "normal")};
  font-style: ${(props) => (props.fontStyle ? "italic" : "normal")};
  font-size: ${(props) => `${props.fontSize}px`};
  font-family: ${(props) => props.fontFamily};
  text-decoration: ${(props) => (props.textDecoration ? "underline" : "none")};
  color: ${(props) => props.color};
`;

const CardRelatedProducts = ({
  handleOrderCheck,
  order,
  checked,
  showControllers,
  styles,
  status,
  isRelatedGoods,
 currentCurrency,
  ...props
}) => {
  return (
    <Item>
      <Label>
        <Checkbox
          key={order.id}
          checked={checked}
          status={status}
          orderStatus={order.status}
          showControllers={showControllers}
          fill={styles.main_relatedProducts_item_checkbox_fill_color}
          color={styles.main_relatedProducts_item_title_checkbox_color}
          onChange={() => handleOrderCheck(order.id)}
          isRelatedGoods={isRelatedGoods}
          {...props}
        />
        <Information>
          <Span
            style={{ width: "60%" }}
            color={styles.main_relatedProducts_item_title_color}
            fontFamily={styles.main_relatedProducts_item_title_fontFamily}
            fontSize={styles.main_relatedProducts_item_title_fontSize}
            fontStyle={styles.main_relatedProducts_item_title_fontStyle}
            fontWeight={styles.main_relatedProducts_item_title_fontWeight}
            textDecoration={
              styles.main_relatedProducts_item_title_textDecoration
            }
          >
            {props?.name}
          </Span>
          <Span
            color={styles.main_relatedProducts_item_price_color}
            fontFamily={styles.main_relatedProducts_item_price_fontFamily}
            fontSize={styles.main_relatedProducts_item_price_fontSize}
            fontStyle={styles.main_relatedProducts_item_price_fontStyle}
            fontWeight={styles.main_relatedProducts_item_price_fontWeight}
            textDecoration={
              styles.main_relatedProducts_item_price_textDecoration
            }
          >
            {parseFloat(props?.price).toFixed(2)}{" "}
            <span className="price">{currentCurrency}</span>
          </Span>
        </Information>
      </Label>
    </Item>
  );
};

export default CardRelatedProducts;
