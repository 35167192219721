import React, { useState } from "react";
import { Card } from "../index";
import { Checkbox } from "../index";
import styled from "@emotion/styled";
import ImagesContainer from "../ImagesContainer";
import classes from "./OrderItem.module.css";

const Wrapper = styled.div``;
const Label = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  padding-bottom: 10px;
  padding-left: 10px;
`;
const Information = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #C7C7C7;
  padding: 15px 12px;
  padding-bottom: 11px;
  align-items: center;
`;

const Items = styled.ul`
  display: ${(props) => (props.isHidden ? "none" : "block")};
`;

const Span = styled.span`
  font-weight: ${(props) => (props.fontWeight ? "bold" : "normal")};
  font-style: ${(props) => (props.fontStyle ? "italic" : "normal")};
  font-size: ${(props) => `${props.fontSize}px`};
  font-family: ${(props) => props.fontFamily};
  text-decoration: ${(props) => (props.textDecoration ? "underline" : "none")};
  color: ${(props) => props.color};
`;
const WrapperIcon = styled.div`
  color: #772af3;
  display: flex;
  grid-gap: 10px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  & img {
    margin-left: 10px;
  }
`;

const OrderItem = ({
  order,
  isFixed,
  handleOrderCheck,
  styles,
  status,
  showControllers,
  isVisibleIcons,currentCurrency
}) => {
  const [isHidden, setHidden] = useState(true);
  const hideDetails = () => {
    isHidden ? setHidden(false) : setHidden(true);
  };
  const countVideos = order?.videos?.length;
  const countImages = order?.photos?.length;
  return (
    <Wrapper>
      <Label>
        {isFixed || order.isPaid ? null : (
          <Checkbox
            orderStatus={order.status}
            showControllers={showControllers}
            status={status}
            fill={styles.main_recommendation_item_checkbox_fill_color}
            color={styles.main_recommendation_item_checkbox_color}
            checked={!order.deleted}
            onChange={() => {
              return showControllers ? handleOrderCheck(order.id) : false;
            }}
          />
        )}
        <Information className={classes.mobile} onClick={hideDetails}>
          <Span
            style={{ width: "100%" }}
            color={
              styles.main_recommendation_item_title_color ||
              styles.main_action_item_title_color
            }
            fontFamily={
              styles.main_recommendation_item_title_fontFamily ||
              styles.main_action_item_title_fontFamily
            }
            fontSize={
              styles.main_recommendation_item_title_fontSize ||
              styles.main_action_item_title_fontSize
            }
            fontStyle={
              styles.main_recommendation_item_title_fontStyle ||
              styles.main_action_item_title_fontStyle
            }
            fontWeight={
              styles.main_recommendation_item_title_fontWeight ||
              styles.main_action_item_title_fontWeight
            }
            textDecoration={
              styles.main_recommendation_item_title_textDecoration ||
              styles.main_action_item_title_textDecoration
            }
          >
            {order ? order.name : "Описание отсутствует"}
            <div className={classes.mobile__count}>
              {!!countImages && isVisibleIcons && (
                <WrapperIcon>
                  {countImages}
                  <span className="file_text">фото</span>
                </WrapperIcon>
              )}
              {!!countVideos && isVisibleIcons && (
                <WrapperIcon>
                  {countVideos}
                  <span className="file_text">видео</span>
                </WrapperIcon>
              )}
            </div>
          </Span>
          <div className={classes.desctop__count}>
            {!!countImages && isVisibleIcons && (
              <WrapperIcon>
                {countImages}
                <span className="file_text">фото</span>
              </WrapperIcon>
            )}
            {!!countVideos && isVisibleIcons && (
              <WrapperIcon>
                {countVideos} <span className="file_text">видео</span>
              </WrapperIcon>
            )}
          </div>

          <Span
            style={{
              marginLeft: "20px",
              maxWidth: "30%",
              textAlign: "right",
              display: "flex",
              justifyContent: "end",
            }}
            color={
              styles.main_recommendation_item_price_color ||
              styles.main_action_item_price_color
            }
            fontFamily={
              styles.main_recommendation_item_price_fontFamily ||
              styles.main_action_item_price_fontFamily
            }
            fontSize={
              styles.main_recommendation_item_price_fontSize ||
              styles.main_action_item_price_fontSize
            }
            fontStyle={
              styles.main_recommendation_item_price_fontStyle ||
              styles.main_action_item_price_fontStyle
            }
            fontWeight={
              styles.main_recommendation_item_price_fontWeight ||
              styles.main_action_item_price_fontWeight
            }
            textDecoration={
              styles.main_recommendation_item_price_textDecoration ||
              styles.main_action_item_price_textDecoration
            }
          >
            {order.price ? parseFloat(order.price).toFixed(2) : ""}{" "}
            <span className="price notranslate" style={{marginRight:order.isRelatedGoods ? "24px" : "0px"}}> {currentCurrency}</span>
            <span className="price">{order.isPaid && " (Оплачен)"}</span>
            {
                 !order.isRelatedGoods && (
                  <img
                      src={
                        isHidden
                            ? require("../../assets/bottom_new.svg")
                            : require("../../assets/top_new.svg")
                      }
                      style={{ marginLeft: "11px" }}
                  />
              )
            }
          </Span>
        </Information>
      </Label>
      <Items isHidden={isHidden}>
        {order?.media && order.media.length ? (
          <ImagesContainer imageUrls={order.media}></ImagesContainer>
        ) : null}
        {order?.repairWork?.map((el) => (
          <Card
              key={el.id}
            currentCurrency={currentCurrency}
            color={styles.page_colors_recommedations}
            styles={styles}
            {...el}
          />
        ))}
        {order?.repairItem?.map((el) => (
          <Card
            key={el.id}
            currentCurrency={currentCurrency}
            color={styles.page_colors_recommedations}
            styles={styles}
            {...el}
          />
        ))}
      </Items>
    </Wrapper>
  );
};

export default OrderItem;
