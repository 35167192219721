import { toast } from 'react-toastify'

const defaultToastConfig = {
  position: toast.POSITION.TOP_RIGHT,
}

export const notifySuccess = message => {
  toast.success(message, defaultToastConfig)
}

export const notifyWarn = message => {
  toast.warn(message, defaultToastConfig)
}

export const notifyError = (
  message = 'Something went wrong. We sorry about that.'
) => {
  toast.error(message, defaultToastConfig)
}
