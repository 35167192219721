import React, { useState, useEffect, useRef, useCallback } from "react";
import { Wrapper, OrderWrapper } from "./Order.styles";
import { Loader } from "../../components/index";
import MainHeader from "../../components/MainHeader";
import OrderHeader from "../../components/OrderHeader/OrderHeader";
import { OrderFooter } from "../../components/OrderFooter";
import styled from "@emotion/styled";
import { CheckListTitle } from "../../components/Checklist/CheckListTitle";
import request from "../../utils/api";
import {
  getFormatedDate,
  getParameterByName,
  toDataUrl,
} from "../../utils/helpers";
import { useLocation } from "wouter";
import useModal from "../../hooks/useModal";
import Modal from "../../components/ModalSuccessDiagnostic/ModalSuccess";
import {
  BASE_URL,
  MODAL_TYPE_DIAGNOSTICS,
  SUCCESS_ORDER,
} from "../../utils/constants";
import { getCheckList, getFullOrder } from "../../utils/data";

const WrapperMain = styled.div`
  width: 100%;
`;
const WrapperMaster = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 133px 0px 133px;
  @media (max-width: 650px) {
    padding: 0 15px 0 15px;
  }
`;
export const RowMaster = styled.div`
  display: flex;
  color: #999999;
  font-size: 15px;
  @media (max-width: 650px) {
    font-size: 12px;
  }
`;
export const RowMasterSpan = styled.span`
  display: flex;
  color: #111;
  margin-left: 10px;
  font-size: 15px;
  @media (max-width: 650px) {
    font-size: 12px;
  }
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
`;
const Signature = styled.img`
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin-right: 25px;
`;

const WrapperDiagnostics = styled.div`
  padding: 0px 133px 0px 133px;
  margin: 30px 0px 30px;
  @media (max-width: 650px) {
    padding: 0 15px 0 15px;
  }
`;
const WrapperRecommendation = styled.div`
  padding: 0px 133px 0px 133px;
  margin: 0px 0px 30px 0px;

  @media (max-width: 650px) {
    padding: 0 15px 0 15px;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  border-top: 1px dashed rgb(185, 185, 185);
  padding-bottom: 24px;

  margin: 0 auto;
`;
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 50px;
`;

export const ConfirmButton = styled.button`
  color: #fff;
  background: ${(props) => (props.buttonColor ? props.buttonColor : "#772af3")};
  border-radius: 30px;
  padding: 17px 24px 15px 24px;
  border: none;
  cursor: pointer;
  margin: 0 auto;
  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  text-align: left;
  padding: 0px 10px;
`;
const Description = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  padding: 0px 10px;
`;
export const NotificationContainer = styled.div`
  display: flex;
  height: 88px;
  padding: 30px 130px 0px;
  @media (max-width: 650px) {
    padding: 30px 15px 0px 15px;
  }
`;
export const Notification = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 20px;
  background: #f8f8ff;
  -webkit-print-color-adjust: exact;
  border-radius: 10px;
  color: #111;
  font-weight: bold;
  font-size: 17px;
  line-height: 140%;
  @media (max-width: 650px) {
    line-height: 20px;
  }
`;
const Icon = styled.img`
  object-fit: cover;
  width: 16px;
  height: 16px;
  margin-right: 15px;
`;

const Diagnostics = ({ params }) => {
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [, setLocation] = useLocation();
  const [fullOrder, setFullOrder] = useState({});
  const [checkList, setCheckList] = useState([]);
  const { toggle, visibleModalDiagnostics } = useModal();
  const [modalText, setModalText] = useState(SUCCESS_ORDER);

  const componentRef = useRef();

  const successRequest = async () => {
    setLoading(true);
    try {
      await request({
        url: `/full_orders/${fullOrder.id}/calculate`,
        method: "put",
        data: {},
      });
    } catch (e) {
      setModalText(`${e.response.data.detail}`);
      console.error(e);
    } finally {
      setLoading(false);
      toggle(MODAL_TYPE_DIAGNOSTICS);
    }
  };

  const checkCode = async () => {
    try {
      const res = await request({
        url: `/check_code`,
        method: "post",
        data: { code: code || getParameterByName("code"), type: 1 },
      });
      return res;
    } catch (e) {
      console.error(e);
      setLocation("/error");
      return false;
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const adminCheck = getParameterByName('admin')
      let orderObj;
      try {
        orderObj = await getFullOrder(params.code);
      } catch (e) {
        console.error(e);
      } finally {
        setFullOrder(orderObj.data?.items[0]);
      }
      // SET CHECK-LIST
      let checkListObj;
      try {
        checkListObj = await getCheckList(orderObj.data?.items[0]?.checkList.id);
      } catch (e) {
        console.error(e);
      } finally {
        setCheckList(checkListObj);
      }

      try {
        await request({
          url: `/full_orders/${orderObj.data?.items[0].id}/log_event`,
          method: "POST",
          data: {
            eventSource: "browser",
            eventType: adminCheck==='' ? 16 : 14,
          },
        });
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    })();
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <Wrapper>
      <Modal
        visible={visibleModalDiagnostics}
        toggle={() => toggle(MODAL_TYPE_DIAGNOSTICS)}
        text={modalText}
      />
      <MainHeader />
      <OrderWrapper>
        <OrderHeader
          dealer={fullOrder?.dealerShip}
          car={fullOrder?.car}
          customer={fullOrder?.customer}
          companyLogo={fullOrder?.dealerShip?.company?.logo}
          orderDate={getFormatedDate(fullOrder?.dateCreate)}
          componentRef={componentRef}
          diagnosticsTemplate
          companyLegalName={fullOrder?.companyLegalName}
          isFullOrderLegalNamePrimary={
            fullOrder?.dealerShip?.company.isFullOrderLegalNamePrimary
          }
        />

        <WrapperMain>
          <NotificationContainer>
            <Notification>
              <Icon src={require("../../assets/info_icon.svg")} alt="icon" />
              Ваш автомобиль находится в сервисе на стадии формирования
              заказ-наряда по работам
            </Notification>
          </NotificationContainer>
        </WrapperMain>

        <WrapperMain>
          <WrapperDiagnostics>
            {checkList?.data?.items.map(
              (checkListItem) =>
                !checkListItem.fields.every((field) => !field.value) && (
                  <CheckListTitle
                    border
                    key={checkListItem.id}
                    title={checkListItem.name}
                    fields={checkListItem.fields}
                  />
                )
            )}
          </WrapperDiagnostics>
        </WrapperMain>

        {fullOrder?.checkList?.recommendation && (
          <WrapperMain>
            <WrapperRecommendation>
              <Column>
                <Title>Рекомендации от дилера</Title>
                <Description>
                  {fullOrder?.checkList?.recommendation}
                </Description>
              </Column>
            </WrapperRecommendation>
          </WrapperMain>
        )}

        <WrapperMain>
          <Line style={{ maxWidth: "514px" }} />
          <WrapperMaster>
            <Column>
              <Signature
                src={
                  fullOrder?.user?.photo
                    ? `${process.env.REACT_APP_BASEURL}/uploads/${fullOrder.user.photo.path}`
                    : require("../../assets/avatar.svg")
                }
                alt="заглушка"
              />
            </Column>
            <Column>
              <RowMaster>Мастер консультант:</RowMaster>
              <RowMaster>Контактный телефон:</RowMaster>
            </Column>
            <Column>
              <RowMasterSpan>{fullOrder?.user?.secondName}</RowMasterSpan>
              <RowMasterSpan>{fullOrder?.user?.phone}</RowMasterSpan>
            </Column>
          </WrapperMaster>
        </WrapperMain>

        <ButtonWrapper>
          {!fullOrder?.isCalculationOrdered && (
            <ConfirmButton onClick={successRequest}>
              Получить рассчет по заказ-наряду
            </ConfirmButton>
          )}
        </ButtonWrapper>

        <OrderFooter
          dealer={fullOrder?.dealerShip}
          companyLogo={
            fullOrder?.dealerShip?.logo
              ? fullOrder?.dealerShip?.logo
              : fullOrder?.dealerShip?.company?.logo || ""
          }
          email={fullOrder?.dealerShip?.email}
          phone={fullOrder?.dealerShip?.phone}
          isFullOrderLegalNamePrimary={
            fullOrder?.dealerShip?.company.isFullOrderLegalNamePrimary
          }
          companyLegalName={fullOrder?.companyLegalName}
          diagnosticsTemplate
        />
      </OrderWrapper>
    </Wrapper>
  );
};

export default Diagnostics;
