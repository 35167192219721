import styled from '@emotion/styled'

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: #E5E5E5;
`

export const CodeWrapper = styled.div`
  max-width: 780px;
  background: #FFFFFF;
  padding: 50px;
  margin: 0 auto;
  margin-top: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);
  @media (max-width: 650px) {
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    max-width: 328px;
  }
`

export const OrderNumber = styled.span`
@media (max-width: 650px) {
    display:none;
  }
`
export const Title = styled.h3`
  margin: 0 auto;
  @media (max-width: 650px) {
    font-size: 17px;
  }
`
export const Price = styled.h3`
    color: #772AF3;
    margin: 8px auto 50px auto;
    font-weight: 600;
      @media (max-width: 650px) {
        font-size: 17px;
        text-align: center;
        margin: 7px auto 15px auto;
      }
`
export const Number = styled.div`
    font-weight: 400;
    font-size: 17px;
    padding-bottom: 40px;
  @media (max-width: 650px) {
    max-width: 254px;
    text-align: center;
  }
`
export const CodeContent = styled.div`
  text-align: center;
  padding-bottom: 70px;
`
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
`;
export const ConfirmButton = styled.button`
  width: 100%;
  background: #772af3;
  border-radius: 30px;
  color: #ffffff;
  padding: 15px 24px 15px 24px;
  border: none;
  cursor: pointer;
  &:disabled {
    background: #C7C7C7;
    cursor: not-allowed;
  }
  &:focus {
    outline: none
  }
`;
