import React, { useState, useEffect } from "react";
import request from "../../utils/api";
import {
  Wrapper,
  OrderWrapper,
  HistoryTitle,
  ConfirmButton,
  ButtonWrapper,
} from "./Order.styles";
import { OrderHeader, OrderMain, OrderFooter, Loader } from "../../components";
import { useLocation } from "wouter";
import MainHeader from "../../components/MainHeader";
import {
  getParameterByName,
  getFormatedDate,
  getFonts,
} from "../../utils/helpers";
import PaymentSection from "../../components/PaymentSection";
import { notifyError } from "../../utils/notify";
import { getDealership } from "../../utils/data";
import { useHistory } from "react-router-dom";
import {
  tinkoffCreditStatuses,
  TINKOFF_CREDIT_STATUS,
  VSEGDADA_CREDIT_STATUS,
  TYPE_CREDIT_TINKOFF,
  TYPE_CREDIT_VSGDADA,
  vsegdaDaStatuses,
  STATUS_PAY_1C,
  STATUS_PAY_SBP,
} from "../../utils/constants";

const Order = ({ params }) => {
  const [, setLocation] = useLocation();
  const [styles, setStyles] = useState(null);
  const [loading, setLoading] = useState(false);
  const [ordersHistory, setOrderHistory] = useState([]);
  const [orderHistoryId, setOrderHistoryId] = useState();
  const [mainOrder, setMainOrder] = useState(null);
  const [lastOrders, setLastOrders] = useState([]);
  const [isActualOrder, setIsActualOrder] = useState(false);
  const [moreLoading, setMoreLoading] = useState(false);
  const [dateMatching, setDateMatching] = useState();
  const [code, setCode] = useState("");
  const [loadNew, setLoadNew] = useState(false);
  const history = useHistory();
  const [isNeedCheckCode, setIsNeedCheckCode] = useState(true);
  const [sbpUrl, setSbpUrl] = useState(null);
  const [companyLegalName, setCompanyLegalName] = useState(null);
  const paymentParameter = getParameterByName("payment");
  const isPayment = paymentParameter ? JSON.parse(paymentParameter) : null;
  const handleRecommendedWorkStatusChange = (id) => {
    if (!mainOrder) return;
    setMainOrder((oldData) => {
      let recommendPrice = oldData.recommendPrice;
      let relatedGoodsPrice = oldData.relatedGoodsPrice;
      let totalPrice = oldData.totalPrice;
      return {
        ...oldData,
        recommendationWork: oldData.recommendationWork.map((item) => {
          if (item.id === id) {
            if (item.deleted) {
              if (!item.isRelatedGoods) {
                recommendPrice += parseFloat(item.price || 0);
              } else {
                relatedGoodsPrice += parseFloat(item.price || 0);
              }
              totalPrice += parseFloat(item.price || 0);
            } else {
              if (!item.isRelatedGoods) {
                recommendPrice -= parseFloat(item.price || 0);
              } else {
                relatedGoodsPrice -= parseFloat(item.price || 0);
              }
              totalPrice -= parseFloat(item.price || 0);
            }
          }
          return item.id === id ? { ...item, deleted: !item.deleted } : item;
        }),
        recommendPrice: recommendPrice,
        relatedGoodsPrice: relatedGoodsPrice,
        totalPrice: totalPrice,
      };
    });
  };

  const sendPaymentRequest = async (type) => {
    let data;
    if (type === STATUS_PAY_1C || type === STATUS_PAY_SBP) {
      data = {
        fullOrder: `/api/full_orders/${mainOrder.id}`,
      };
      setIsNeedCheckCode(false);
    } else {
      data = {
        fullOrder: `/api/full_orders/${mainOrder.id}`,
        type,
      };
    }
    try {
      setLoading(true);
      const res = await request({
        url: "/orders",
        method: "post",
        data,
      });
     if (type === STATUS_PAY_1C || type === STATUS_PAY_SBP) {
        return window.location.assign(res.data);
      }
      if (!res.data.paymentUrl && type !== STATUS_PAY_1C) {
        notifyError("Произошла ошибка!");
      } else if (type !== STATUS_PAY_1C) {
        window.location.assign(res.data.paymentUrl);
      }
    } catch (e) {
      notifyError(e.response.data.detail);
    } finally {
      setLoading(false);
    }
  };

  const fetchMoreData = async (id) => {
    setMoreLoading(true);
    await fetchDataHistoryById(id);
    setMoreLoading(false);
  };

  const fetchDataHistoryById = async (
    id,
    rewrite = false,
    withPreloader = false
  ) => {
    if (withPreloader) {
      setLoading(true);
    }
    const mainOrder = {};
    let fixedWorks;
    let fullOrder;
    let totalPrice = 0;
    let relatedGoodsPrice = 0;
    let recommendPrice = 0;
    let fixedPrice = 0;
    try {
      const response = await request({
        url: `/full_order_history/${id}`,
        method: "get",
        params: {
          limit: 999,
        },
      });
      fullOrder = response.data;

      mainOrder.id = response.data.id;
      mainOrder.customer = fullOrder.customer;
      mainOrder.orderNumber = fullOrder.repairName;
      mainOrder.orderDate = getFormatedDate(response.data.parentDateCreate);
      mainOrder.updateDate = getFormatedDate(response.data.dateMatching);
      mainOrder.currentDate = fullOrder.dateUpdate;
    } catch {
      setLoading(false);
      setLocation("/error");
    }

    try {
      fixedWorks = await request({
        url: `/order_reason_histories`,
        method: "get",
        params: {
          "fullOrderHistory.id": fullOrder.id,
          deleted: false,
          limit: 999,
        },
      });

      if (fixedWorks.data.items.length) {
        fixedWorks.data.items.forEach(async (work, i) => {
          if (!work.isPaid) {
            totalPrice += parseFloat(work.price || 0);
            fixedPrice += parseFloat(work.price || 0);
          }
          fixedWorks.data.items[i].repairItem =
            fixedWorks.data.items[i].repairItemHistory;
          fixedWorks.data.items[i].repairWork =
            fixedWorks.data.items[i].repairWorkHistory;
        });
        mainOrder.customer = fullOrder.customer;
        mainOrder.orderNumber = fullOrder.repairName;
        mainOrder.fixedWork = fixedWorks.data.items;
      } else {
        mainOrder.fixedWork = 0;
      }
    } catch (e) {
      setLoading(false);
      setLocation("/error");
    }
    const recommendations = await request({
      url: `/recommendations_history`,
      method: "get",
      params: {
        "fullOrderHistory.id": fullOrder.id,
        limit: 999,
      },
    });
    const servicePackets = [];
    for (const el of recommendations.data.items) {
      const response = await request({
        url: `/service_packet_histories`,
        method: "GET",
        params: {
          "recommendation.id": el.id,
          "price[exists]": true,
          limit: 999,
        },
      });
      // eslint-disable-next-line no-loop-func
      response.data.items.forEach((packet) => {
        if (packet.status !== "rejected" && !packet.isPaid) {
          totalPrice += parseFloat(packet.price || 0, 100);
          if (!packet.isRelatedGoods) {
            recommendPrice += parseFloat(packet.price || 0, 100);
          } else {
            relatedGoodsPrice += parseFloat(packet.price || 0, 100);
          }
        } else {
          packet.deleted = true;
        }
        const formatedPacket = {
          ...packet,
          name: packet.name ? packet.name : "Не указано",
          media: packet.photos.concat(packet.videos),
          repairItem: packet.repairItemHistory,
          repairWork: packet.repairWorkHistory,
        };
        servicePackets.push(formatedPacket);
      });
    }
    mainOrder.servicePackets = servicePackets;
    mainOrder.car = fullOrder.car;
    const dealerResult = await request({
      url: `/dealer_ships/${fullOrder.dealerShip.id}`,
    });
    mainOrder.worker = fullOrder.user;
    const pageStyles = await request({
      url: `/styles`,
      params: { "dealership.id": fullOrder.dealerShip.id },
    });
    const dealerStyles = JSON.parse(pageStyles.data.items[0]?.styleJson);
    const orderPdf = await request({
      url: `/order_pdfs?fullOrder.id=${fullOrder.id}&order[id]=desc`,
    });
    if (orderPdf.data.items.length) {
      mainOrder.pdf = orderPdf.data.items[0].pdf.path;
    }
    getFonts(dealerStyles);

    setStyles(dealerStyles);
    mainOrder.recommendationWork = servicePackets;
    mainOrder.dealer = dealerResult.data;
    mainOrder.totalPrice = totalPrice;
    mainOrder.relatedGoodsPrice = relatedGoodsPrice;
    mainOrder.recommendPrice = recommendPrice;
    mainOrder.fixedPrice = fixedPrice;

    if (rewrite) {
      setMainOrder(mainOrder);
    } else {
      setLastOrders((oldArray) => [...oldArray, mainOrder]);
    }
    if (withPreloader) {
      setLoading(false);
    }
  };

  const checkCode = async () => {
    try {
      const res = await request({
        url: `/check_code`,
        method: "post",
        data: { code: code || getParameterByName("code") },
      });
      if (
        res.data["Id заказа: "] === window.location.pathname.split("/order/")[1]
      ) {
        return true;
      }
      setLocation("/error");
    } catch (e) {
      return false;
    }
  };

  const fetchData = async (id, withPreloader = true) => {
    if (withPreloader) {
      setLoading(true);
    }
    const mainOrder = {};
    let fixedWorks;
    let fullOrder;
    let totalPrice = 0;
    let relatedGoodsPrice = 0;
    let recommendPrice = 0;
    let fixedPrice = 0;
    let fullOrderHistory;
    try {
      const response = await request({
        url: `/full_orders`,
        method: "get",
        params: {
          code: params.code,
          limit: 999,
          needToPay: true,
        },
      });
      fullOrder = response.data.items[0];
      if (isPayment !== null) {
        if (isPayment) {
          setLocation(`/success-payment?id=${fullOrder.id}`);
        } else {
          setLocation(`/error-payment?id=${fullOrder.id}`);
        }
      }
      setCompanyLegalName(fullOrder?.companyLegalName);
      setSbpUrl(fullOrder.sbpUrl);
      if (getParameterByName("admin") !== "") {
        if (fullOrder.status === "closed") {
        fullOrder.closed_timeout &&
          localStorage.setItem(
            "dateClose",
            getFormatedDate(fullOrder.closed_timeout)
          );
        localStorage.setItem(
          "dealershipName",
          fullOrder.dealershipName
        );
        setLoading(false);
        setLocation("/close-order");
      } else if (
        !fullOrder.isNeedToPay &&
        fullOrder.readyToPay
      ) {
        localStorage.setItem("repairName", fullOrder.repairName);
        setLoading(false);
        setLocation("/success-payment");
      } else if (
        !fullOrder.readyToPay &&
        !fullOrder.isCustomerCanSee
      ) {
        localStorage.setItem(
          "dealershipName",
          fullOrder.dealershipName
        );
        fullOrder.paidTimeout &&
          localStorage.setItem(
            "dateClose",
            getFormatedDate(fullOrder.paidTimeout)
          );
          fullOrder.completeTimeout &&
          localStorage.setItem(
            "dateClose",
            getFormatedDate(fullOrder.completeTimeout)
          );
        setLoading(false);
        setLocation("/close-order");
      }
    }

      const date = getFormatedDate(fullOrder.dateUpdate);
      const updateDate = getFormatedDate(new Date());
      mainOrder.customer = fullOrder.customer;
      mainOrder.externalId = fullOrder.externalId;
      mainOrder.id = fullOrder.id;
      mainOrder.status = fullOrder.status;
      mainOrder.orderDate = date;
      mainOrder.updateDate = updateDate;
      mainOrder.currentDate = fullOrder.dateUpdate;

      fullOrderHistory = await request({
        url: `/full_order_history`,
        method: "get",
        params: {
          externalId: fullOrder.externalId,
          limit: 999,
          "order[dateCreate]": "desc",
        },
      });

      setOrderHistory(fullOrderHistory.data.items);
      const dateMatching = getFormatedDate(
        fullOrderHistory.data.items.dateMatching
      );
      setDateMatching(dateMatching);
    } catch {
      setLoading(false);
      setLocation("/error");
    }
    try {
      fixedWorks = await request({
        url: `/order_reasons`,
        method: "get",
        params: {
          "fullOrder.id": fullOrder.id,
          deleted: false,
          limit: 999,
        },
      });
      if (fixedWorks.data.items.length) {
        fixedWorks.data.items.forEach(async (work) => {
          if (!work.isPaid) {
            totalPrice += parseFloat(work.price || 0);
            fixedPrice += parseFloat(work.price || 0);
          }
        });
        mainOrder.fixedWork = fixedWorks?.data?.items;
      }
    } catch (e) {
      setLoading(false);
      setLocation("/error");
    }
    mainOrder.orderNumber = fullOrder?.repairName;

    const servicePackets = [];
    const response = await request({
      url: `/service_packets`,
      method: "GET",
      params: {
        "recommendation.fullOrder.externalId": fullOrder?.externalId,
        "price[exists]": true,
        limit: 999,
        deleted: false,
      },
    });
    response.data.items.forEach((packet) => {
      if (packet.isRelatedGoods && packet.status === "new") {
        packet.deleted = true;
      } else if (packet.status !== "rejected" && !packet.isPaid) {
        totalPrice += parseFloat(packet.price || 0, 100);
        if (packet.status === "confirmed") {
          fixedPrice += parseFloat(packet.price || 0, 100);
        } else {
          if (!packet.isRelatedGoods) {
            recommendPrice += parseFloat(packet.price || 0, 100);
          } else {
            relatedGoodsPrice += parseFloat(packet.price || 0, 100);
          }
        }
      } else {
        packet.deleted = true;
      }
      const formatedPacket = {
        ...packet,
        name: packet.name ? packet.name : "Не указано",
        media: packet.photos.concat(packet.videos),
      };
      servicePackets.push(formatedPacket);
    });
    mainOrder.car = fullOrder.car;
    mainOrder.worker = fullOrder.user;
    const pageStyles = await request({
      url: `/styles`,
      params: { "dealership.id": fullOrder.dealerShip.id },
    });
    const dealerStyles = JSON.parse(pageStyles.data.items[0]?.styleJson);
    const orderPdf = await request({
      url: `/order_pdfs?fullOrder.id=${fullOrder.id}&order[id]=desc`,
    });
    if (orderPdf.data.items.length) {
      mainOrder.pdf = orderPdf.data.items[0].pdf.path;
    }
    getFonts(dealerStyles);
    setStyles(dealerStyles);

    try {
      const { data } = await getDealership(fullOrder.dealerShip.id);
      mainOrder.dealer = data;
    } catch (e) {
      console.error(e);
    }
    mainOrder.recommendationWork = servicePackets;
    mainOrder.totalPrice = totalPrice;
    mainOrder.recommendPrice = recommendPrice;
    mainOrder.relatedGoodsPrice = relatedGoodsPrice;
    mainOrder.fixedPrice = fixedPrice;
    mainOrder.readyToPay = fullOrder.readyToPay;
    if (withPreloader) {
      setLoading(false);
      setMainOrder(mainOrder);
    }

    return { mainOrder: mainOrder, oldVersions: fullOrderHistory.data.items };
  };

  useEffect(() => {
    setCode(getParameterByName("code") || "");
    (async () => {
      setLoading(true);
      const adminCheck = getParameterByName("admin");
      const res = await fetchData();
      try {
        await request({
          url: `/full_orders/${res.mainOrder.id}/log_event`,
          method: "POST",
          data: {
            eventSource: "browser",
            eventData: res.mainOrder.readyToPay ? "Оплата" : "Согласование",
            eventType: adminCheck === "" ? 15 : 5,
          },
        });
      } catch (e) {
        console.error(e);
      }

      try {
        const response = await request({
          url: `/orders?fullOrder.id=${res.mainOrder.id}&order[id]=desc`,
          method: "get",
        });
        const typeCredit = response.data?.items[0]?.type;
        const tinkoffCreditStatus =
          response.data?.items[0]?.tinkoffCreditStatus;
        const vsegdaDaCreditStatus =
          response.data?.items[0]?.vsegdaDaCreditStatus;
        if (typeCredit === TYPE_CREDIT_TINKOFF) {
          if (tinkoffCreditStatus === tinkoffCreditStatuses[0].id) {
            localStorage.setItem(
              TINKOFF_CREDIT_STATUS,
              tinkoffCreditStatuses[0].rejected
            );
          } else if (tinkoffCreditStatus === tinkoffCreditStatuses[1].id) {
            localStorage.setItem(
              TINKOFF_CREDIT_STATUS,
              tinkoffCreditStatuses[1].approved
            );
          } else if (tinkoffCreditStatus === tinkoffCreditStatuses[2].id) {
            localStorage.setItem(
              TINKOFF_CREDIT_STATUS,
              tinkoffCreditStatuses[2].signed
            );
          } else if (tinkoffCreditStatus === tinkoffCreditStatuses[3].id) {
            localStorage.setItem(
              TINKOFF_CREDIT_STATUS,
              tinkoffCreditStatuses[3].canceled
            );
          }
        } else {
          localStorage.removeItem(TINKOFF_CREDIT_STATUS);
        }

        if (typeCredit === TYPE_CREDIT_VSGDADA) {
          if (vsegdaDaCreditStatus === vsegdaDaStatuses.CREATED) {
            localStorage.setItem(
              VSEGDADA_CREDIT_STATUS,
              vsegdaDaStatuses.CREATED
            );
          } else if (vsegdaDaCreditStatus === vsegdaDaStatuses.REJECTED) {
            localStorage.setItem(
              VSEGDADA_CREDIT_STATUS,
              vsegdaDaStatuses.REJECTED
            );
          } else if (vsegdaDaCreditStatus === vsegdaDaStatuses.WAIT_APPROVE) {
            localStorage.setItem(
              VSEGDADA_CREDIT_STATUS,
              vsegdaDaStatuses.WAIT_APPROVE
            );
          } else if (vsegdaDaCreditStatus === vsegdaDaStatuses.APPROVED) {
            localStorage.setItem(
              VSEGDADA_CREDIT_STATUS,
              vsegdaDaStatuses.APPROVED
            );
          } else if (vsegdaDaCreditStatus === vsegdaDaStatuses.SHOP_APPROVED) {
            localStorage.setItem(
              VSEGDADA_CREDIT_STATUS,
              vsegdaDaStatuses.SHOP_APPROVED
            );
          } else if (
            vsegdaDaCreditStatus === vsegdaDaStatuses.FINALLY_APPROVED
          ) {
            localStorage.setItem(
              VSEGDADA_CREDIT_STATUS,
              vsegdaDaStatuses.FINALLY_APPROVED
            );
          } else if (vsegdaDaCreditStatus === vsegdaDaStatuses.SIGNED) {
            localStorage.setItem(
              VSEGDADA_CREDIT_STATUS,
              vsegdaDaStatuses.SIGNED
            );
          } else if (vsegdaDaCreditStatus === vsegdaDaStatuses.ACTIVE) {
            localStorage.setItem(
              VSEGDADA_CREDIT_STATUS,
              vsegdaDaStatuses.ACTIVE
            );
          } else if (vsegdaDaCreditStatus === vsegdaDaStatuses.ERROR) {
            localStorage.setItem(
              VSEGDADA_CREDIT_STATUS,
              vsegdaDaStatuses.ERROR
            );
          } else if (vsegdaDaCreditStatus === vsegdaDaStatuses.CANCELED) {
            localStorage.setItem(
              VSEGDADA_CREDIT_STATUS,
              vsegdaDaStatuses.CANCELED
            );
          }
        } else {
          localStorage.removeItem(VSEGDADA_CREDIT_STATUS);
        }
      } catch (e) {
        console.error(e);
      }
      if (res.mainOrder.readyToPay) {
        setMainOrder(res.mainOrder);
        setIsActualOrder(true);
        setLoading(false);
        return;
      }
      if (res.mainOrder.status === "new") {
        setMainOrder(res.mainOrder);
        setIsActualOrder(true);
      } else if (res.mainOrder.status === "processed") {
        setMainOrder(res.mainOrder);
        setIsActualOrder(true);
      } else if (res.mainOrder.status === "new" && !res.oldVersions.length) {
        setLocation("/error");
      } else if (res.mainOrder.status === "new") {
        setMainOrder(null);
        setIsActualOrder(false);
      } else if (res.mainOrder.status === "completed") {
        // возможно удалить
        setMainOrder(null);
        setIsActualOrder(false);
      }
      if (res && res.oldVersions.length) {
        if (res.oldVersions[0]) {
          await fetchDataHistoryById(res.oldVersions[0].id);
        }
        if (res.oldVersions[1]) {
          await fetchDataHistoryById(res.oldVersions[1].id);
        }
      }

      setLoading(false);
    })();
  }, []);
  const goToScroll = () => {
    const el = document.getElementById("scrollEl");
    if (el) el.scrollIntoView();
  };

  return loading ? (
    <Loader />
  ) : (
    <Wrapper>
      <MainHeader
        goToScroll={goToScroll}
        showHistory={!mainOrder?.readyToPay}
        currentOrderDate={mainOrder?.currentDate}
        currentOrderId={mainOrder?.externalId}
        ordersHistory={ordersHistory}
        fetchDataHistoryById={fetchDataHistoryById}
        fetchCurrentData={fetchData}
        isActualOrder={isActualOrder}
      />
      {mainOrder && styles ? (
        <OrderWrapper>
          <>
            <OrderHeader
              customer={mainOrder.customer}
              car={mainOrder.car}
              dealer={mainOrder.dealer}
              orderNumber={mainOrder?.orderNumber}
              orderDate={mainOrder.orderDate}
              updateDate={mainOrder.updateDate}
              companyLegalName={companyLegalName}
              isFullOrderLegalNamePrimary={
                mainOrder.dealer?.company.isFullOrderLegalNamePrimary
              }
              companyLogo={
                mainOrder.dealer.logo
                  ? mainOrder.dealer.logo
                  : mainOrder.dealer.company.logo || ""
              }
              styles={styles}
            ></OrderHeader>
            {mainOrder.readyToPay ? (
              <PaymentSection
                dealerResultCompany={mainOrder.dealer.company}
                currency={mainOrder.dealer.company.currency}
                sendPaymentRequest={sendPaymentRequest}
                recommendations={mainOrder?.recommendationWork}
                fixedWork={mainOrder?.fixedWork}
                orderId={mainOrder.id}
                price={mainOrder.totalPrice}
                recommendPrice={mainOrder.recommendPrice}
                relatedGoodsPrice={mainOrder.relatedGoodsPrice}
                fixedPrice={mainOrder?.fixedPrice}
                styles={styles}
                orderNumber={mainOrder.orderNumber}
                sbpUrl={sbpUrl}
              ></PaymentSection>
            ) : (
              <OrderMain
                showControllers={true}
                currency={mainOrder.dealer.company.currency}
                orderHistoryId={orderHistoryId}
                setLoading={setLoading}
                recommendations={mainOrder.recommendationWork}
                handleRecommendedWorkStatusChange={
                  handleRecommendedWorkStatusChange
                }
                fixedWork={mainOrder?.fixedWork}
                withTheCode={mainOrder.dealer.company.isMatchingWithTheCode}
                worker={mainOrder.worker}
                orderId={mainOrder.id}
                price={mainOrder.totalPrice}
                recommendPrice={mainOrder.recommendPrice}
                relatedGoodsPrice={mainOrder.relatedGoodsPrice}
                fixedPrice={mainOrder.fixedPrice}
                orderNumber={mainOrder?.orderNumber}
                contractOffer={mainOrder?.dealer?.company?.contractOffer}
                customer={mainOrder?.customer}
                styles={styles}
                orderPdf={mainOrder?.pdf}
              ></OrderMain>
            )}
            <OrderFooter
              dealer={mainOrder.dealer}
              styles={styles}
              companyLegalName={companyLegalName}
              isFullOrderLegalNamePrimary={
                mainOrder?.dealer?.company.isFullOrderLegalNamePrimary
              }
              companyLogo={
                mainOrder.dealer.logo || mainOrder.dealer.company.logo || ""
              }
              fax={mainOrder.dealer.company.fax}
              requisites={mainOrder.dealer.company.requisites}
              actualAddress={mainOrder.dealer.company.actualAddress}
              legalAddress={mainOrder.dealer.company.legalAddress}
              legalName={mainOrder.dealer?.company.legalName}
              email={mainOrder.dealer.company.email || mainOrder.dealer.email}
              phone={mainOrder.dealer.company.phone || mainOrder.dealer.phone}
            />
          </>
        </OrderWrapper>
      ) : null}
      {!getParameterByName("history") ? (
        <>
          {lastOrders && lastOrders.length && mainOrder ? (
            <HistoryTitle id="scrollEl">Предыдущие согласования</HistoryTitle>
          ) : null}
          {lastOrders.map((order, index) => (
            <>
              <OrderWrapper style={{ marginTop: "20px" }}>
                {order && styles ? (
                  <>
                    <OrderHeader
                      isCompleted={true}
                      customer={order.customer}
                      car={order.car}
                      dealer={order.dealer}
                      orderNumber={order.orderNumber}
                      orderDate={order.orderDate}
                      updateDate={order.updateDate}
                      companyLegalName={companyLegalName}
                      isFullOrderLegalNamePrimary={
                        order?.dealer?.company.isFullOrderLegalNamePrimary
                      }
                      companyLogo={
                        order.dealer.logo || order.dealer.company.logo || ""
                      }
                      styles={styles}
                    ></OrderHeader>
                    <OrderMain
                      showControllers={false}
                      currency={order.dealer.company.currency}
                      orderHistoryId={orderHistoryId}
                      setLoading={setLoading}
                      recommendations={order.recommendationWork}
                      handleRecommendedWorkStatusChange={
                        handleRecommendedWorkStatusChange
                      }
                      fixedWork={order.fixedWork}
                      worker={order.worker}
                      orderId={order.id}
                      price={order.totalPrice}
                      recommendPrice={order.recommendPrice}
                      relatedGoodsPrice={order.relatedGoodsPrice}
                      fixedPrice={order.fixedPrice}
                      styles={styles}
                      orderNumber={order?.orderNumber}
                      customer={order?.customer}
                      orderPdf={order?.pdf}
                    />
                    <OrderFooter
                      dealer={order.dealer}
                      styles={styles}
                      companyLogo={
                        order.dealer.logo || order.dealer.company.logo || ""
                      }
                      fax={order.dealer.company.fax}
                      requisites={order.dealer.company.requisites}
                      actualAddress={order.dealer.company.actualAddress}
                      legalAddress={order.dealer.company.legalAddress}
                      legalName={order.dealer.company.legalName}
                      email={order.dealer.company.email || order.dealer.email}
                      phone={order.dealer.company.phone || order.dealer.phone}
                      companyLegalName={companyLegalName}
                      isFullOrderLegalNamePrimary={
                        order?.dealer?.company.isFullOrderLegalNamePrimary
                      }
                    />
                  </>
                ) : null}
              </OrderWrapper>
              {index === 0 && lastOrders.length > 1 && !mainOrder ? (
                <HistoryTitle id="scrollEl">
                  Предыдущие согласования
                </HistoryTitle>
              ) : null}
            </>
          ))}
          {lastOrders.length && lastOrders.length < ordersHistory.length ? (
            <ButtonWrapper>
              <ConfirmButton
                onClick={() =>
                  fetchMoreData(ordersHistory[lastOrders.length].id)
                }
                disabled={moreLoading}
              >
                <span>{moreLoading ? "Загрузка..." : "Показать еще"}</span>
              </ConfirmButton>
            </ButtonWrapper>
          ) : null}
        </>
      ) : null}
    </Wrapper>
  );
};

export default Order;
