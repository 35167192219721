import React from "react";
import styled from "@emotion/styled";
import { CheckList } from "../index";

const Main = styled.main`
  flex: 1;
  width: 100%;
  background: ${(props) => props.background};
`;

const OrderMain = ({
  setLoading,
  recommendations,
  fixedWork,
  worker,
  orderId,
  price,
  recommendPrice,
  fixedPrice,
  styles,
  orderPdf,
  handleRecommendedWorkStatusChange,
  showControllers,
  relatedGoodsPrice,
  orderNumber,
  customer, contractOffer,currency, withTheCode
}) => {
  return (
    <Main background={styles.main_background_color}>
      <CheckList
        showControllers={showControllers}
        setLoading={setLoading}
        currency={currency}
        recommendations={recommendations}
        handleRecommendedWorkStatusChange={handleRecommendedWorkStatusChange}
        fixedWork={fixedWork}
        worker={worker}
        withTheCode={withTheCode}
        orderId={orderId}
        price={price}
        recommendPrice={recommendPrice}
        relatedGoodsPrice={relatedGoodsPrice}
        fixedPrice={fixedPrice}
        orderNumber={orderNumber}
        contractOffer={contractOffer}
        styles={styles}
        orderPdf={orderPdf}
        customer={customer}
      ></CheckList>
    </Main>
  );
};
export default OrderMain;