import React from "react";
import { CodeWrapper, Title, Wrapper, ReasonText, ImageContainer } from "./SuccessOrder.styles";

const SuccessOrder = () => {

  return (
    <Wrapper>
      <CodeWrapper>
        <ImageContainer src={require("../../assets/done_icon.png")}/>
        <Title>Спасибо, что доверяете нам!</Title>
        <ReasonText>Наша команда уже приступила к работе. Очень скоро мы свяжемся с Вами!</ReasonText>
        <button onClick={() => window.history.go(-1)} style={{backgroundColor: '#772af3', fontSize: '17px', padding: '10px 36px' , borderRadius: '30px', border: 'none', color: '#fff', marginTop: '15px', cursor: 'pointer'}}>Назад к истории</button>
      </CodeWrapper>
    </Wrapper>
  );
};

export default SuccessOrder;
