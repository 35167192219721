import styled from '@emotion/styled'

export const Wrapper = styled.div`
    width: 100%;
    height: 100vh;
    background: #E5E5E5;
    @media (max-width: 650px) {
      height: 100%;
    }
`

export const CodeWrapper = styled.div`
    max-width: 780px;
    background: #FFFFFF;
    padding: 120px 60px;
    margin: 0 auto;
    margin-top: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);
    @media (max-width: 650px) {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
      margin-top: 0;
    }
`

export const ImageContainer = styled.img`
    margin-bottom: 40px;
`

export const Title = styled.div`
    font-size: 22px;
    line-height: 26px;
    font-weight: bold;
    margin-bottom: 15px;
    text-align: center;
`
export const CodeContent = styled.div`
    
`

export const ReasonText = styled.div`
    max-width: 45%;
    text-align: center;
    font-size: 17px;
    line-height: 20px;
    @media (max-width: 650px) {
      max-width: 100%;
    }
`