import React from "react";
import styled from "@emotion/styled/macro";

const CheckboxContainer = styled.label`
  margin-right: 20px;
  display: inline-block;
  vertical-align: middle;
`;
const Icon = styled.svg`
  fill: none;
  stroke: ${(props) => props.fill || "#111"};
  stroke-width: 2px;
`;
const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
HiddenCheckbox.defaultProps = { type: "checkbox" };

const StyledCheckbox = styled.div`
  opacity: ${(props) => (!props.showControllers ? "0.5" : "1")};
  display: inline-block;
  border: ${(props) =>  props.error
          ? "1px solid red"
          : props.checked
                  ? props.color ? `1px solid ${props.color}` :  '1px solid #772af3'
                  : `1px solid #AAAAAA`};
  width: 20px;
  height: 20px;
  background: ${(props) =>
    props.color
      ? props.checked
        ? props.color
        : "#fff"
      : props.checked
      ? "#772af3"
      : "#fff"};
  border-radius: 2px;
  transition: all 150ms;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 0px ${(props) => props.color};
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;

const Checkbox = ({
  className,
  checked,
  error,
  fill,
  color,
  status,
  orderStatus,
  showControllers = true,
  isRelatedGoods,
  ...props
}) => (
    <CheckboxContainer className={className}>
        <HiddenCheckbox
            checked={checked}
            showControllers={showControllers}
            disabled={
                window.location.search.includes("history") ||
                (isRelatedGoods &&
                    (orderStatus === "confirmed" || orderStatus === "rejected"))
            }
            color={color}
            {...props}
        />
        <StyledCheckbox
            showControllers={showControllers}
            checked={checked}
            error={error}
            color={color}
            disabled={
                window.location.search.includes("history") ||
                (isRelatedGoods &&
                    (orderStatus === "confirmed" || orderStatus === "rejected"))
            }
        >
            <Icon fill={fill} viewBox="0 0 22 22" color={color} checked={checked}>
                <polyline points="4 11 8 15 16 6"></polyline>
            </Icon>
        </StyledCheckbox>
    </CheckboxContainer>
)

export default Checkbox;
