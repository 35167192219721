import React from 'react'

import {
  MainPageWrapper,
  MainPageText,
  MainPageImg,
  Contact,
  TizerContacts,
} from './MainPage.styles'

import { useLocation } from 'wouter'

import { ReactComponent as EmailIcon } from '../../assets/email-icon.svg'
import { ReactComponent as PhoneIcon } from '../../assets/phone-icon.svg'
import {
  ButtonWrapper,
  ConfirmButton,
} from '../PersonConfirmation/PersonConfirmation.styles'

const MainPage = () => {
  const [, setLocation] = useLocation()

  const handleClick = () => {
    setLocation('/confirmation')
  }
  return (
    <MainPageWrapper>
      <MainPageText>
        <div className="text__logo">
          <img
            src={require('../../assets/tizer-logo-big.png')}
            style={{ width: '100%' }}
          />
        </div>
        <TizerContacts>
          {/* <Contact>
                        <PhoneIcon/> <span style={{marginLeft: '19px', textAlign: 'left'}}>+7 (900) 123-45-67 </span>
                    </Contact>
                    <Contact>
                        <EmailIcon/> <span style={{marginLeft: '19px', textAlign: 'left'}}>example@mail.ru</span>
                    </Contact> */}
          <ButtonWrapper>
            <ConfirmButton onClick={handleClick}>Ввести код</ConfirmButton>
          </ButtonWrapper>
        </TizerContacts>
      </MainPageText>
      <MainPageImg>
        <img src={require('../../assets/hand.png')} style={{ width: '100%' }} />
      </MainPageImg>
    </MainPageWrapper>
  )
}

export default MainPage
