import styled from "@emotion/styled";

export const Footer = styled.footer`
  width: 100%;
  background-color: ${(props) => (props.color ? props.color : "#F8F8FF")};
  -webkit-print-color-adjust: exact;
  border-top: 1px dashed #b9b9b9;
  padding: 30px 40px;
`;
export const Circle = styled.div`
  background: #C7C7C7;
  border-radius: 50%;
  height: 4px;
  width: 4px;
  @media (max-width: 600px) {
    display: none;
  }
`;
export const Logo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 650px) {
    padding: 30px 15px 0px 15px;
  }
`;
export const Contacts = styled.div`
  display: flex;
  flex-direction: column;
`;
export const FooterInfo = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 80px;
  @media (max-width: 700px) {
    padding: 30px 0;
    flex-direction: column;
  }
`;
export const Agreement = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: 600px) {
    align-items: flex-start;
    padding-bottom: 24px;
  }
`;
export const Link = styled.a`
  color: #772AF3;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-decoration: none;
  white-space: nowrap;
  padding-top: 30px;

  &:last-child {
    margin-top: 12px;
  }

  @media (max-width: 600px) {
    &:first-child {
      margin-top: 40px;
    }
  }

`;
export const Info = styled.div`
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
`;

export const Label = styled.span`
  font-weight: ${(props) => (props.fontWeight ? "bold" : 300)};
  font-style: ${(props) => (props.fontStyle ? "italic" : "normal")};
  font-size: 12px;
  text-decoration: ${(props) => (props.textDecoration ? "underline" : "none")};
  color: ${(props) => props.color};
  margin-left: 5px;
`;


export const ImageContainer = styled.img`
  max-width: ${(props) => props.maxSize}%;
  object-fit: contain;
  @media (max-width: 600px) {
    margin-top: 20px;
  }
`;
