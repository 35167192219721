import axios from 'axios'
import {BASE_URL, CLIENT_ID, CLIENT_SECRET} from "./constants";

let requestQueue = []
let lastRequest = {}

const service = axios.create({
  baseURL: BASE_URL,
})

let isPending = false
const authData = {
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
}
const fullTokenUrl = BASE_URL + '/oauth/v2/token'

export async function refreshToken(refresh_token) {
    const params = {
        ...authData,
        grant_type: 'refresh_token',
        refresh_token,
    }
    try {
        return await axios({
            url: fullTokenUrl,
            method: 'get',
            params,
        })
    } catch (e) {
        isPending = false
        return Promise.reject(e)
    }
}


const serviceDecorator = (config) => {
  return new Promise(async function (resolve, reject) {
    config.url = `${process.env.REACT_APP_API_PREFIX}${config.url}`
    try {
      const response = await service(config)
      resolve(response)
    } catch (error) {
      console.log(`Error in request: ${error}`)
      reject(error)
    }
  })
}

service.interceptors.request.use(
  (config) => {
    config.headers = {
      ...config.headers,
      accept: 'application/json',
      'Content-Type': 'application/json',
    }
    config.params = {
      ...config.params,
      deleted: false,
    }
    const token = localStorage.getItem('access_token')
    const roles = localStorage.getItem("permissions")
    const isAdmin = roles?.includes("ROLE_ADMIN")
    const isPutMethod = config.method === "put"
    if (config.url.indexOf('/full_orders') !== -1 && isAdmin && isPutMethod) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    console.log('error on request', error)
    return Promise.reject(error)
  }
)

function resendPendingRequests() {
  requestQueue.forEach(async (deferredRequest) => {
    const config = deferredRequest.config
    const resolve = deferredRequest.resolve
    try {
      const response = await service(config)
      resolve(response)
    } catch (error) {
      console.log(`errResolve ${error}`)
    }
  })
  requestQueue = []
}

service.interceptors.response.use(
  (response) => {
    lastRequest = {}
    return response
  },
  async (error, request) => {
    if (error.response && error.response.status === 401) {
      if (error.response.data.error === 'access_denied') {
        return console.error('Нет доступа')
      }
      if (!isPending) {
          isPending = true
          const refresh_token = localStorage.getItem('refresh_token')
          refreshToken(refresh_token)
              .then((response) => {
                  localStorage.setItem('access_token', response?.data?.access_token)
                  localStorage.setItem('refresh_token', response?.data?.refresh_token)
                  isPending = false
                  resendPendingRequests()
              })
              .catch(() => {
                  localStorage.clear()
              })

      }

      return new Promise((resolve, reject) => {
        lastRequest.resolve = resolve
        lastRequest.reject = reject
        requestQueue.push({ resolve, reject, config: error.config })
      })
    } else {
      return new Promise((resolve, reject) => {
        reject(error)
      })
    }
  }
)


export default serviceDecorator
