import React from 'react'
import styled from '@emotion/styled'
import { ImageStatusBlock } from './ImageStatusBlock'
import { TYPE_DATE, TYPE_PERCENT, TYPE_TEXT } from '../../utils/constants'
import { getChecklistDate } from '../../utils/helpers'

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
  -webkit-print-color-adjust: exact;
  padding: 14px 16px 0px 16px;
`

const Item = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-bottom: 14px;
  font-size: 14px;
  font-weight: 300;
  @media (max-width: 650px) {
    font-size: 16px;
  }
`
const Line = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  border-bottom: 0.5px solid #9999995c;
  margin-right: -30px;
`
const RightLabel = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  color: #999999;
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
`
const Label = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  width: 100%;
`

export const BodyCheckListDiagnostics = ({
  name,
  type,
  value,
  index,
  length,
}) => (
  <BodyWrapper>
    <Item>
      <ImageStatusBlock type={type} value={value} />
      <Label>{name}</Label>
      {type === TYPE_PERCENT && <RightLabel>{value}%</RightLabel>}
      {type === TYPE_TEXT && (
        <RightLabel style={{ minWidth: '100px' }}>{value}</RightLabel>
      )}
      {type === TYPE_DATE && (
        <RightLabel style={{ minWidth: '100px' }}>
          {getChecklistDate(value)}
        </RightLabel>
      )}
    </Item>
    {index !== length - 1 && <Line />}
  </BodyWrapper>
)
