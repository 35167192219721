export const phoneNumber = "+7 (900) 123-45-67";
export const email = "example@test.ru";
export const GOOGLE_API_KEY = "AIzaSyDsFj0D0fqbDsSjGl6dHQXwrxJyh4HuTY4";

export const months = [
  "январь",
  "февраль",
  "март",
  "апрель",
  "май",
  "июнь",
  "июль",
  "август",
  "сентябрь",
  "октябрь",
  "ноябрь",
  "декабрь",
];

export const tinkoffCreditStatuses = [
  { rejected: "rejected", id: 2 },
  { approved: "approved", id: 1 },
  { signed: "signed", id: 3 },
  { canceled: "canceled", id: 4 },
];

export const vsegdaDaStatuses = {
  CREATED: "CREATED",
  REJECTED: "REJECTED",
  WAIT_APPROVE: "WAIT_APPROVE",
  APPROVED: "APPROVED",
  SHOP_APPROVED: "SHOP_APPROVED",
  FINALLY_APPROVED: "FINALLY_APPROVED",
  SIGNED: "SIGNED",
  ACTIVE: "ACTIVE",
  ERROR: "ERROR",
  CANCELED: "CANCELED",
};

export const KIA_COMPANY =359;
export const TEST_COMPANY = 3;
export const PRIVACY_LINK = '/privacy';
export const AGREEMENT_LINK = '/agreement';
export const BASE_URL = process.env.REACT_APP_BASEURL;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;
export const MIN_PRICE_VSEGDADA = 1500;
export const MIN_PRICE_TINKOFF = 3000;
export const FILES_URL = process.env.REACT_APP_BASEURL + '/uploads/'
export const MAX_PRICE = 200000;
export const STATUS_PAY_TINKOFF = 0;
export const STATUS_PAY_SBERBANK = 3;
export const STATUS_PAY_SBP = 5;
export const STATUS_PAY_1C = 4;
export const STATUS_PAY_PLAN_TINKOFF = 1;
export const STATUS_PAY_PLAN_VSEGDADA = 2;
export const TINKOFF_CREDIT_STATUS = "tinkoffCreditStatus";
export const VSEGDADA_CREDIT_STATUS = "vsegdaDaCreditStatus";
export const TYPE_CREDIT_TINKOFF = 1;
export const TYPE_CREDIT_VSGDADA = 2;
export const SUCCESS_ORDER = `Расчет по Заказ-наряду формируется, в ближайшее время, оповестим вас о готовности`;
export const TYPE_TEXT = 0;
export const TYPE_BOOL = 1;
export const TYPE_INT = 2;
export const TYPE_LEVEL = 3;
export const TYPE_PERCENT = 4;
export const TYPE_DATE = 5;
export const MODAL_TYPE_PAY_ONLINE = 1;
export const MODAL_TYPE_PAY_CREDIT = 2;
export const MODAL_TYPE_DIAGNOSTICS = 3;
export const MODAL_TYPE_CONFIRM_ORDER = 4;
