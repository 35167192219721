import React, { useState } from "react";
import styles from "./MainHeader.module.css";
import styled from "@emotion/styled";
import { useLocation } from "wouter";
import { getParameterByName } from "../../utils/helpers";

const Logo = styled.img`
  max-width: ${(props) => props.maxSize}px;
  object-fit: contain;
  margin-left: 100px;
  @media (max-width: 790px) {
    max-width: 140px !important;
    margin-left: 10px;
  }
`;

const Icon = styled.img`
  width: ${(props) => (props.active ? "10px" : "20px")};
  height: ${(props) => (props.active ? "10px" : "20px")};
  object-fit: contain;
  margin-right: 7px;
`;

const ButtonHistory = styled.button`
  min-width: 250px;
  background: #772af3;
  border-radius: 30px;
  border: none;
  color: #fff;
  padding: 10px 36px;
  align-items: flex-end;
  margin-right: 100px;
  cursor: pointer;
  @media (max-width: 790px) {
    min-width: 40px;
    height: 40px;
    padding: 4px 6px;
    margin-right: 10px;
    border-radius: 50%;
  }
`;

const Label = styled.button`
  display: flex;
  align-items: center;
  max-width: 250px;
  background-color: #efeff4;
  border-radius: 30px;
  color: #111;
  padding: 10px 16px;
  margin-right: 10px;
  cursor: pointer;
  outline: none;
  border: none;
`;

function MainHeader({
  goToScroll,
  access,
  isActualOrder,
  ordersHistory,
  fetchDataHistoryById,
  currentOrderDate,
  currentOrderId,
  fetchCurrentData,
  showHistory,
}) {
  const [actually, setActually] = useState(false);
  const [active, setActive] = useState(true);
  const [location, setLocation] = useLocation();

  const getFormatedDate = (val) => {
    let message = "";
    let newDate = new Date(val);
    let year = newDate.getFullYear();
    let month = newDate.getMonth();
    let day = newDate.getDate();
    let hour = newDate.getHours();
    let minute = newDate.getMinutes();
    message += `${day}.${month + 1}.${year},${twoDigitPad(hour)}:${twoDigitPad(
      minute
    )}`;
    return message;
  };

  const twoDigitPad = (num) => {
    return num < 10 ? "0" + num : num;
  };

  const changeUrl = (param) => {
    if (param) {
      setLocation(`?history=${param}`);
    } else {
      setLocation(location);
    }
  };

  return (
    <div className={styles.header}>
      <div className={styles.container}>
        <div className={styles.row}>
          <Logo
            maxSize={"170"}
            src={require("../../assets/tizer-logo-big.png")}
          />
          {!getParameterByName("history") && showHistory && (
            <ButtonHistory
              className={styles.button__history}
              onClick={goToScroll}
            >
              <img
                className={styles.iconButton}
                src={require("../../assets/bytesize_archive.svg")}
              />
              <span>История согласований</span>
            </ButtonHistory>
          )}
        </div>
        {showHistory && (
          <div className={styles.rowSlider}>
            {isActualOrder ? (
              <Label
                className={
                  !getParameterByName("history") && styles["btnCurrent"]
                }
                onClick={() => {
                  fetchCurrentData(currentOrderId, true);
                  changeUrl();
                }}
              >
                <Icon
                  active={active}
                  src={
                    active
                      ? require("../../assets/passiveSvg.svg")
                      : require("../../assets/activeSvg.svg")
                  }
                />
                <span>{getFormatedDate(currentOrderDate)}</span>
              </Label>
            ) : null}

            {ordersHistory?.map((order) => (
              <Label
                className={
                  order.id === +getParameterByName("history") &&
                  styles["btnCurrent"]
                }
                onClick={() => {
                  fetchDataHistoryById(order.id, true, true);
                  changeUrl(order.id);
                }}
              >
                {order.id === +getParameterByName("history") ? (
                  <>
                    <Icon
                      active={actually}
                      src={require("../../assets/activeGrey.svg")}
                    />
                    <span>{getFormatedDate(order.parentDateCreate)}</span>
                  </>
                ) : (
                  <>
                    <Icon
                      active={actually}
                      src={
                        actually
                          ? require("../../assets/passiveSvg.svg")
                          : require("../../assets/activeSvg.svg")
                      }
                    />
                    <span>{getFormatedDate(order.parentDateCreate)}</span>
                  </>
                )}
              </Label>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default MainHeader;
