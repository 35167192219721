import request from './api'

export const getDealership = async id => {
  const res = await request({
    url: `/dealer_ships/${id}`,
  })
  return res
}

export const getFullOrder = async id => {
  const res = await request({
    url: `/full_orders?code=${id}`,
  })
  return res
}

export const getCheckList = async id => {
  const res = await request({
    url: `/check_list/result?checkList.id=${id}`,
    method: 'get',
  })
  return res
}
