import React, { useState, useContext } from 'react'
import styled from '@emotion/styled'
import { ReactComponent as Arrow } from '../../assets/arrow_new.svg'
import { BodyCheckListDiagnostics } from './BodyCheckListDiagnostics'
import { AccordionContext } from '../../App'

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ border }) => (border ? '0px' : '25px')};
  margin-top: ${({ border }) => (border ? '12px' : '30px')};
  cursor: pointer;
  border-bottom: ${({ border, closeBorder }) =>
    border && !closeBorder ? '0.5px solid #9999995c' : 'none'};
  padding: ${({ border }) => (border ? '0px 7px 11px' : '0px')};
  background-color: #EFEFF4;
  border-radius: 10px;
  padding: 15px 12px;
`

const Title = styled.div`
  font-weight: bold;
  font-style: ${({ fontStyle }) => (fontStyle ? 'italic' : 'normal')};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '20px')};
  font-family: ${({ fontFamily }) => fontFamily};
  text-decoration: ${({ textDecoration }) =>
    textDecoration ? 'underline' : 'none'};
  color: ${({ color }) => color};
  line-height: 26px;
`

const TitleCircle = styled.div`
border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: #003b79;
  display: flex;
  align-items: center;
  justify-content: center;
`
const TitleInfo = styled.div`
  color: #999999;
  font-size: 10px;
`
const TitleArrow = styled.div`
display: flex;
  align-items: center;
`

const TitleText = styled.span`
margin-right: 10px;
`
const defaultStyles = {
  color: '#00000',
  fontFamily: '',
  fontSize: '',
  fontStyle: '',
  fontWeight: '',
  textDecoration: '',
}

export const CheckListTitle = ({
  fields,
  isArrowActive,
  title,
  onClick,
  styles = defaultStyles,
  border,
}) => {
  const [toggle, toggleAccordion] = useState(false)
  const [toggleAll, toggleAllAccordion] = useContext(AccordionContext)
  const setToggle = () => {
    toggleAccordion(!toggle)
  }
  return (
    <>
      <TitleWrapper
        closeBorder={toggle || toggleAll}
        border={border}
        onClick={onClick || setToggle}
      >
      <TitleArrow>
        <Title
            color={styles.color}
            fontFamily={styles.fontFamily}
            fontSize={styles.fontSize}
            fontStyle={styles.fontStyle}
            fontWeight={styles.fontWeight}
            textDecoration={styles.textDecoration}
            toggleAll={toggleAll}
        >
          {title}
        </Title>
      </TitleArrow>
        <TitleInfo>
          <TitleText>
              {isArrowActive ? "Скрыть": "Показать"}
          </TitleText>
            <Arrow
                viewBox="0 0 12 7"
                style={{
                    transform:
                        (isArrowActive || toggle || toggleAll) && 'rotate(180deg)',
                    transition: 'all 0.2s ease 0s',
                }}
            />
        </TitleInfo>
      </TitleWrapper>
      {fields?.length &&
        (toggle || toggleAll) &&
          fields.map((field, i) => field.value && <BodyCheckListDiagnostics index={i} length={fields?.length} type={field.type} value={field.value} name={field.name} />)}
    </>
  )
}
