import React, { useMemo } from 'react'
import ReactDOM from 'react-dom'
import classes from './ModalPay.module.css'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
  MAX_PRICE,
  MIN_PRICE_TINKOFF,
  MIN_PRICE_VSEGDADA,
  MODAL_TYPE_PAY_CREDIT,
  STATUS_PAY_PLAN_TINKOFF,
  STATUS_PAY_PLAN_VSEGDADA,
} from '../../utils/constants'

const Modal = ({
  visible,
  toggle,
  pay,
  dealerResultCompany,
  totalPrice,
  loading,
  loadingVsegdaDA,
}) => {
  const minPriceTinkoff = useMemo(
    () => totalPrice >= parseFloat(MIN_PRICE_TINKOFF),
    [totalPrice]
  )
  const minPriceVsegdada = useMemo(
    () => totalPrice >= parseFloat(MIN_PRICE_VSEGDADA),
    [totalPrice]
  )
  const maxPrice = useMemo(
    () => totalPrice <= parseFloat(MAX_PRICE),
    [totalPrice]
  )

  return visible
    ? ReactDOM.createPortal(
        <div className={classes.modal}>
          <div className={classes.modal__pop} role="dialog" aria-modal="true">
            {!loading && !loadingVsegdaDA && (
              <button
                className={classes.closeIcon}
                type="button"
                onClick={() => toggle(MODAL_TYPE_PAY_CREDIT)}
              />
            )}
            <div className={classes.img_logo}></div>
            <image
              src={require('../../assets/hand.png')}
              alt="svg"
              style={{ width: '100%' }}
            />
            <div className={classes.modal__title}>Оформление рассрочки</div>
            <div className={classes.modal__desc}>
              Выберите сервис для оформления рассрочки
            </div>
            <div className={classes.buttons__wrapper}>
              {minPriceTinkoff &&
                maxPrice &&
                dealerResultCompany.shopId &&
                dealerResultCompany.showcaseId && (
                  <Button
                    disabled={loading || loadingVsegdaDA}
                    onClick={() => pay(STATUS_PAY_PLAN_TINKOFF)}
                    variant="contained"
                    className={classes.tinkoff}
                    startIcon={loading && <CircularProgress size={10} />}
                  >
                    Рассрочка Тинькофф
                  </Button>
                )}
              {minPriceVsegdada &&
                dealerResultCompany.vsegdaDaCode &&
                dealerResultCompany.vsegdaDaPassword &&
                dealerResultCompany.vsegdaDaUsername && (
                  <Button
                    disabled={loading || loadingVsegdaDA}
                    onClick={() => pay(STATUS_PAY_PLAN_VSEGDADA)}
                    variant="contained"
                    className={classes.vsegdada}
                    startIcon={
                      loadingVsegdaDA && <CircularProgress size={10} />
                    }
                  >
                    Рассрочка Всегда.Да
                  </Button>
                )}
            </div>
          </div>
          <div className={classes.modal__overlay}></div>
        </div>,
        document.body
      )
    : null
}

export default Modal
