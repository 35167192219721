import styled from '@emotion/styled'

export const Description = styled.div`
  max-width: 342px;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 40px;
  & span {
    font-weight: bold;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: #e5e5e5;
  @media (max-width: 650px) {
    height: 100%;
  }
`

export const CodeWrapper = styled.div`
  max-width: 780px;
  background: #ffffff;
  padding: 120px 60px;
  margin: 0 auto;
  margin-top: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);
  @media (max-width: 650px) {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    margin-top: 0;
  }
`

export const ImageContainer = styled.img`
  margin-bottom: 40px;
`

export const Title = styled.div`
  font-size: 22px;
  line-height: 26px;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
`
export const CodeContent = styled.div``

export const ReasonText = styled.div`
  max-width: 45%;
  text-align: center;
  font-size: 17px;
  line-height: 20px;
  @media (max-width: 650px) {
    max-width: 75%;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
`
export const ConfirmButton = styled.button`
  width: 100%;
  background: #772af3;
  border-radius: 30px;
  color: #ffffff;
  padding: 15px 24px 15px 24px;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`

export const ReturnButton = styled.button`
  width: 370px;
  font-size: 17px;
  background: #772af3;
  border-radius: 30px;
  color: #ffffff;
  padding: 15px 24px 15px 24px;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`
