import React from 'react';
import MainHeader from "../../components/MainHeader";
import {CustomFooter} from "../../components";
import {Background,Title,Content,Line,Wrapper,Header} from '../../components'
export const PrivacyPage = () => {
    return (
        <Background>
            <MainHeader />
            <Wrapper>
                <Header>Политика конфиденциальности</Header>

                <Content>
                    <Title>1. Общие положения</Title>
                    <Line>
                        Настоящая Политика конфиденциальности (далее — Политика) мобильного приложения «Тизер.СТО» (далее - Приложение) действует в отношении той информации, которую ООО «Тизер» и/или его аффилированные лица (далее – «Производитель») может получить с устройства Пользователя во время использования им Приложения, во время использования Пользователем любого из сайтов, сервисов, программ, продуктов, оборудования или услуг Производителя (далее - «Сервис») и в ходе исполнения Производителем любых соглашений и договоров с Пользователем.

                    </Line>
                    <Line>
                        Использование Приложения означает безоговорочное согласие пользователя с настоящей Политикой и указанными в ней условиями обработки информации, получаемой с устройства пользователя. В случае несогласия с Политикой пользователь должен воздержаться от использования Приложения.
                    </Line>
                    <Line>
                        Приложение и услуги в рамках Приложения реализуются пользователю на основании договоров и соглашений с Производителем, которые в числе прочего регулируют все вопросы обработки и хранения Производителем персональных данных пользователя.
                    </Line>
                    <Line>
                        Настоящая Политика применима только к Приложению. Производитель не контролирует и не несет ответственность за информацию (последствия её передачи), переданную пользователем третьей стороне, в случае если такая передача была выполнена на ресурсе третьей стороны, на который пользователь мог перейти по ссылкам из Приложения.
                    </Line>
                    <Line>
                        Производитель имеет право вносить изменения в настоящую Политику путем размещения новой редакции Политики на своем официальном сайте: <a href="https://tizersto.ru/" target={"_blank"}>https://tizersto.ru/</a> или в Приложении.
                    </Line>
                    <Line>
                        Обязанность самостоятельного ознакомления с актуальной редакцией Политики лежит на пользователе.
                    </Line>
                    <Title>1. Персональная информация Пользователей, которую обрабатывает Приложение</Title>
                    <Line>
                        1.1. В рамках настоящей Политики под «персональной информацией Пользователя» понимаются:
                    </Line>
                    <Line>
                        1.1.2. Данные, которые автоматически передаются Сервисам в процессе их использования с помощью установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, данные файлов «cookie», информация о браузере Пользователя (или иной программе, с помощью которой осуществляется доступ к Сервисам), технические характеристики оборудования и программного обеспечения, используемых Пользователем, дата и время доступа к Сервисам, адреса запрашиваемых страниц и иная подобная информация.
                    </Line>
                    <Line>
                        1.1.3. Иная информация о Пользователе, обработка которой предусмотрена условиями использования отдельных Сервисов.
                    </Line>
                    <Line>
                        1.2. Настоящая Политика применима только к информации, обрабатываемой в ходе использования Сервисов. Приложение не контролирует и не несет ответственность за обработку информации сайтами третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на сайтах Приложения.
                    </Line>
                    <Line>
                        1.3. Приложение не проверяет достоверность персональной информации, предоставляемой Пользователем, и не имеет возможности оценивать его дееспособность. Однако Приложение исходит из того, что пользователь предоставляет достоверную и достаточную персональную информацию и поддерживает эту информацию в актуальном состоянии.
                    </Line>

                    <Title>2. Цели обработки персональной информации Пользователей</Title>
                    <Line>
                        2.1. Приложение собирает и хранит только ту персональную информацию, которая необходима для предоставления Сервисов или исполнения соглашений и договоров с Пользователем, за исключением случаев, когда законодательством предусмотрено обязательное хранение персональной информации в течение определенного законом срока.

                    </Line>
                    <Line>
                        2.2. Персональную информацию Пользователя обрабатывает в следующих целях:

                    </Line>
                    <Line>
                        2.2.3. Связь с Пользователем, в том числе направление уведомлений, запросов и информации, касающихся использования Сервисов, а также обработка запросов и заявок от Пользователя;

                    </Line>
                    <Line>
                        2.2.4. Улучшение качества Сервисов, удобства их использования, разработка новых Сервисов;

                    </Line>
                    <Line>
                        2.2.5. Таргетирование рекламных материалов;

                    </Line>
                    <Line>
                        2.2.6. Проведение статистических и иных исследований на основе обезличенных данных.

                    </Line>
                    <Title>3. Состав иной информации, которая может быть получена с устройства пользователя при использовании Приложения и цели её получения</Title>
                    <Line>
                        3.1. Информация о местоположении устройства пользователя (на основе данных сети оператора сотовой связи и сигналов GPS)

                    </Line>
                    <Line>
                        Цель: информирование пользователя при использовании Приложения о местоположении дополнительных сервисах, доступных пользователю и обусловленных его местоположением.

                    </Line>
                    <Line>
                        3.2. Фото и видеоизображения, полученные с использованием камеры устройства.

                    </Line>
                    <Line>
                        Цель: получение и использование фото и видео изображений в рамках продукции, оборудования и услуг, реализуемых в Приложении, в том числе для создания и сохранения фото и видео изображений в профиле пользователя в Приложении, получения фото и видеоизображений с целью их распознавания и использования для совершения различных операций в Приложении.

                    </Line>
                    <Line>
                        3.3. Информация о версии операционной системы и модели устройства.

                    </Line>

                    <Line>
                        Цель: анализ возможных ошибок в работе Приложения и совершенствование работы Приложения.

                    </Line>
                    <Line>
                        Для целей анализа Производитель может передавать информацию об операционной системе и модели устройства сторонним организациям в обезличенном виде.

                    </Line>
                    <Line>
                        3.4. Информация об IP-адресе и адресе точки подключения пользователя.

                    </Line>

                    <Line>
                        Цель: повышение безопасности пользователя при использовании Приложения.

                    </Line>
                    <Line>
                        3.5. Аудиоданные, полученные с использованием микрофона устройства (реализуется в Приложении при наличии технической возможности)

                    </Line>
                    <Line>
                        Цель: создание аудиодорожек с использованием Приложения.
                    </Line>
                    <Title>4. Условия обработки персональной информации Пользователей и её передачи третьим лицам</Title>
                    <Line>
                        4.1. Приложение хранит персональную информацию Пользователей в соответствии с внутренними регламентами конкретных сервисов.

                    </Line>
                    <Line>
                        4.2. В отношении персональной информации Пользователя сохраняется ее конфиденциальность, кроме случаев добровольного предоставления Пользователем информации о себе для общего доступа неограниченному кругу лиц. При использовании отдельных Сервисов Приложения, Пользователь соглашается с тем, что определённая часть его персональной информации становится общедоступной.

                    </Line>
                    <Line>
                        4.3. Приложение вправе передать персональную информацию Пользователя третьим лицам в следующих случаях:

                    </Line>
                    <Line>
                        4.3.1. Пользователь выразил согласие на такие действия;

                    </Line>
                    <Line>
                        4.3.2. Передача необходима для использования Пользователем Сервисов Приложения;

                    </Line>
                    <Line>
                        4.3.3. Передача предусмотрена российским или иным применимым законодательством в рамках установленной законодательством процедуры;

                    </Line>
                    <Line>
                        4.3.4. Такая передача происходит в рамках продажи или иной передачи бизнеса (полностью или в части), при этом к приобретателю переходят все обязательства по соблюдению условий настоящей Политики применительно к полученной им персональной информации;

                    </Line>
                    <Line>
                        4.3.5. В целях обеспечения возможности защиты прав и законных интересов Производителя или третьих лиц в случаях, когда Пользователь нарушает настоящую Политику, либо документы, содержащие условия использования конкретных Сервисов Приложения.

                    </Line>
                    <Line>
                        4.3.6. В результате обработки персональной информации Пользователя путем ее обезличивания получены обезличенные статистические данные, которые передаются третьему лицу для проведения исследований, выполнения работ или оказания услуг по поручению Производителя.

                    </Line>
                    <Line>
                        4.4. При обработке персональных данных Пользователей Производитель руководствуется Федеральным законом РФ «О персональных данных».

                    </Line>

                    <Title>5. Изменение и удаление персональной информации. Обязательное хранение данных</Title>

                    <Line>
                        5.1. Пользователь может в любой момент изменить (обновить, дополнить) предоставленную им персональную информацию или её часть, воспользовавшись функцией редактирования персональных данных в разделе «Личный кабинет», Админка.

                    </Line>
                    <Line>
                        5.2. Пользователь также может удалить предоставленную им в рамках определенной учетной записи персональную информацию, отправив запрос на удаление аккаунта предоставив идентификационные данные по электронной почте: <a href="mailto:sales@tizer-team.ru" >sales@tizer-team.ru</a> При этом удаление аккаунта может повлечь невозможность использования некоторых Сервисов Приложения.

                    </Line>
                    <Line>
                        5.3. Права, предусмотренные пунктами 5.1. и 5.2. настоящей Политики могут быть ограничены в соответствии с требованиями законодательства. В частности, такие ограничения могут предусматривать обязанность Производителя сохранить измененную или удаленную Пользователем информацию на срок, установленный законодательством, и передать такую информацию в соответствии с законодательно установленной процедурой государственному органу.

                    </Line>

                    <Title>6. Меры, применяемые для защиты персональной информации Пользователя</Title>
                    <Line>
                        6.1. Производитель принимает необходимые и достаточные организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с ней третьих лиц.

                    </Line>


                    <Title>Изменение Политики конфиденциальности. Применимое законодательство</Title>
                    <Line>
                        7.1. Производитель имеет право вносить изменения в настоящую Политику конфиденциальности. При внесении изменений в актуальной редакции указывается дата последнего обновления.

                    </Line>
                    <Line>
                        7.2. К настоящей Политике и отношениям между Пользователем и Производителем, возникающим в связи с применением Политики конфиденциальности, подлежит применению право Российской Федерации.

                    </Line>
                    <Line>
                        7.3. Новая редакция Политики конфеденциальности вступает в силу с момента ее размещения в сети Интернет по указанному в настоящем пункте адресу, если иное не предусмотрено новой редакцией Соглашения. Действующая редакция Соглашения находится на странице по адресу <a href="https://tizersto.ru/" target={"_blank"}>https://tizersto.ru/</a>

                    </Line>
                    <Title>8. Обратная связь. Вопросы и предложения</Title>
                    <Line>
                        8.1. Все предложения или вопросы по поводу настоящей Политики Пользователь вправе направлять в Службу технической поддержки Производителя по электронной почте: <a href="mailto:sales@tizer-team.ru" >sales@tizer-team.ru</a>

                    </Line>
                </Content>
                <CustomFooter agreement={true} />
            </Wrapper>
        </Background>
    );
};
