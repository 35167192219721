import React, {useContext, useEffect, useState} from "react";
import styled from "@emotion/styled";
import { useReactToPrint } from 'react-to-print';
import { getParameterByName, toDataUrl} from "../../utils/helpers";
import { AccordionContext } from "../../App";
import HtmlPdf from 'html-to-pdf-js'
import defaultImage from '../../assets/tizer-logo-big.png'
import {BASE_URL} from "../../utils/constants";

const Header = styled.header`
  width: 100%;
  background-color: ${(props) => (props.color ? props.color : "#F8F8FF")};
  -webkit-print-color-adjust: exact;
  border-bottom: 1px dashed #b9b9b9;
  padding-bottom: 20px;
`;
const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 30px 133px 0px 133px;
  @media (max-width: 650px) {
    padding: 30px 15px 0px 15px;
  }
`;
const Logo = styled.img`
  max-width: ${(props) => props.maxSize}%;
  object-fit: contain;
`;
const HeaderInfo = styled.div`
  padding: 30px 133px 0px 133px;
  @media (max-width: 650px) {
    padding: 30px 15px 0px 15px;
  }
`;
const Row = styled.div`
  display: flex;
`;
const RowHeader = styled.div`
  display: flex;
  @media (max-width: 650px) {
    flex-direction: column;
  }
`;
const ColumnHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-content: left;
  word-break: break-word;
  width: ${(props) => (props.width ? props.width : "100%")};
  @media (max-width: 650px) {
    width: 100%;
  }
`;
const RowLabels = styled.div`
  display: flex;
  margin-top: 30px;
  color: #000000;
  font-size: 12px;
  font-weight: 300;
  @media (max-width: 650px) {
    flex-direction: column;
  }
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-content: left;
  word-break: break-word;
  width: ${(props) => (props.width ? props.width : "100%")};
`;
const Span = styled.span`
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 20px;
`;
const ButtonDownloadPdf = styled.button`
  color: #772af3;
  border: 1px solid #dadada;
  background-color: #f8f8ff;
  -webkit-print-color-adjust: exact;
  padding: 4px 10px;
  border-radius: 30px;
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
  width: 136px;
  @media (max-width: 650px) {
    width: 100%;
    font-size: 17px;
    padding: 10px;
  }
`;

const ColumnButton = styled.div`
  display: flex;
  align-content: right;
  flex-direction: column;
  align-items: end;
  width: ${(props) => (props.width ? props.width : "100%")};
  word-break: break-word;
  @media (max-width: 650px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;
const ColumnLabels = styled.div`
  display: flex;
  align-content: left;
  align-items: center;
  width: ${(props) => (props.width ? props.width : "100%")};
  word-break: break-word;
  @media (max-width: 650px) {
    width: 100%;
    margin-top: 7px;
  }
`;
const Timestamp = styled.div`
  font-weight: ${({ fontWeight }) => (fontWeight ? "bold" : "normal")};
  font-style: ${({ fontStyle }) => (fontStyle ? "italic" : "normal")};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "14px")};
  font-family: ${({ fontFamily }) => fontFamily};
  text-decoration: ${({ textDecoration }) =>
    textDecoration ? "underline" : "none"};
  color: ${({ color, refresh }) => (color ? color : refresh && "#772AF3")};
  line-height: 18px;
  text-align: right;
`;

const Label = styled.span`
  padding: 5px 0 5px 0;
  font-weight: ${(props) => (props.fontWeight ? "bold" : "normal")};
  font-style: ${(props) => (props.fontStyle ? "italic" : "normal")};
  font-size: ${(props) => `${props.fontSize}px`};
  font-family: ${(props) => props.fontFamily};
  text-decoration: ${(props) => (props.textDecoration ? "underline" : "none")};
  color: ${(props) => props.color};
  line-height: 20px;
`;

const MainLabel = styled.span`
  padding: 5px 0 5px 0;
  font-weight: ${(props) => (props.fontWeight ? "bold" : "normal")};
  font-style: ${(props) => (props.fontStyle ? "italic" : "normal")};
  font-size: ${(props) => `${props.fontSize}px`};
  font-family: ${(props) => props.fontFamily};
  text-decoration: ${(props) => (props.textDecoration ? "underline" : "none")};
  color: ${(props) => props.color};
  line-height: 26px;
`;

const Info = styled.span`
  padding: 5px 0 5px 20px;
  font-weight: ${(props) => (props.fontWeight ? "bold" : "normal")};
  font-style: ${(props) => (props.fontStyle ? "italic" : "normal")};
  font-size: ${(props) => `${props.fontSize}px`};
  font-family: ${(props) => props.fontFamily};
  text-decoration: ${(props) => (props.textDecoration ? "underline" : "none")};
  color: ${(props) => props.color};
  line-height: 20px;
`;

const Completed = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  text-align: right;
  color: #772af3;
`;

const IconDownload = styled.img`
  width: 10px;
  height: 10px;
  margin-right: 6px;
  object-fit: cover;
  @media (max-width: 650px) {
    width: 12px;
    height: 12px;
  }
`;

const defaultStyles = {
  header_background_color: "#F8F8FF",
  logo_max_size: "",
  header_date_color: "#999999",
  header_date_fontFamily: "inherit",
  header_date_fontSize: "12",
  header_date_fontStyle: "",
  header_date_fontWeight: "",
  header_date_textDecoration: "",
  header_mainText_color: "#999999",
};

const OrderHeader = ({
  diagnosticsTemplate = false,
  customer,
  car,
  dealer,
  orderNumber,
  orderDate,
  updateDate = "",
  dateMatching,
  styles = defaultStyles,
  companyLogo,
  companyLegalName,
  isFullOrderLegalNamePrimary,
  isCompleted = false,
}) => {
  const [toggleAll, toggleAllAccordion] = useContext(AccordionContext);
  const [image,setImage] = useState(null)

  const printPdfAndCloseAccordions = async () => {
    await toggleAllAccordion(true);
    await window.print()
    await toggleAllAccordion(false);
  };
  return (
    <Header color={styles.header_background_color}>
      <LogoContainer>
        <Logo
          maxSize={styles.logo_max_size || "25"}
          src={
            companyLogo
              ? image || `${BASE_URL}/uploads/${companyLogo?.path}`
              : defaultImage
          }
          onError={() => setImage(defaultImage)}
        />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Timestamp
            color={styles.header_date_color}
            fontFamily={styles.header_date_fontFamily}
            fontSize={styles.header_date_fontSize}
            fontStyle={styles.header_date_fontStyle}
            fontWeight={styles.header_date_fontWeight}
            textDecoration={styles.header_date_textDecoration}
          >
            {!diagnosticsTemplate && "Создано:"} {orderDate}
          </Timestamp>
          {isCompleted || getParameterByName("history") ? (
            <Column>
              <Completed>Согласован {updateDate}</Completed>
            </Column>
          ) : (
            <Timestamp
              color={styles.header_date_color}
              fontFamily={styles.header_date_fontFamily}
              fontSize={styles.header_date_fontSize}
              fontStyle={styles.header_date_fontStyle}
              fontWeight={styles.header_date_fontWeight}
              textDecoration={styles.header_date_textDecoration}
              refresh
            >
              {!diagnosticsTemplate && "Обновлено:"} {updateDate}
            </Timestamp>
          )}
        </div>
      </LogoContainer>
      <HeaderInfo>
        {diagnosticsTemplate && !toggleAll && (
          <RowHeader>
            <ColumnHeader width="50%">
              <Span>Онлайн диагностика</Span>
            </ColumnHeader>
            <ColumnButton width="50%">
              <ButtonDownloadPdf onClick={printPdfAndCloseAccordions}>
                <IconDownload
                  src={require("../../assets/clarity_download-line.svg")}
                />
                Скачать в PDF
              </ButtonDownloadPdf>
            </ColumnButton>
          </RowHeader>
        )}

        {!diagnosticsTemplate && (
          <Row>
            <Column width="40%">
              <MainLabel
                color={styles.header_mainTitle_color}
                fontFamily={styles.header_mainTitle_fontFamily}
                fontSize={styles.header_mainTitle_fontSize}
                fontStyle={styles.header_mainTitle_fontStyle}
                fontWeight={styles.header_mainTitle_fontWeight}
                textDecoration={styles.header_mainTitle_textDecoration}
              >
                Заказ-наряд
              </MainLabel>
            </Column>
            <Column width="60%">
              <MainLabel
                color={styles.header_secondTitle_color}
                fontFamily={styles.header_secondTitle_fontFamily}
                fontSize={styles.header_secondTitle_fontSize}
                fontStyle={styles.header_secondTitle_fontStyle}
                fontWeight={styles.header_secondTitle_fontWeight}
                textDecoration={styles.header_secondTitle_textDecoration}
                style={{ paddingLeft: "20px" }}
              >
                {orderNumber}
              </MainLabel>
            </Column>
          </Row>
        )}
        <Row>
          <Column width="40%">
            <Label
              color={styles.header_mainText_color}
              fontFamily={styles.header_mainText_fontFamily}
              fontSize={styles.header_mainText_fontSize}
              fontStyle={styles.header_mainText_fontStyle}
              fontWeight={styles.header_mainText_fontWeight}
              textDecoration={styles.header_mainText_textDecoration}
            >
              Заказчик
            </Label>
          </Column>
          <Column width="60%">
            <Info
              color={styles.header_secondText_color}
              fontFamily={styles.header_secondText_fontFamily}
              fontSize={styles.header_secondText_fontSize}
              fontStyle={styles.header_secondText_fontStyle}
              fontWeight={styles.header_secondText_fontWeight}
              textDecoration={styles.header_secondText_textDecoration}
            >
              {customer ? customer.name : "..."}
            </Info>
          </Column>
        </Row>
        <Row>
          <Column width="40%">
            <Label
              color={styles.header_mainText_color}
              fontFamily={styles.header_mainText_fontFamily}
              fontSize={styles.header_mainText_fontSize}
              fontStyle={styles.header_mainText_fontStyle}
              fontWeight={styles.header_mainText_fontWeight}
              textDecoration={styles.header_mainText_textDecoration}
            >
              Автомобиль
            </Label>
          </Column>
          <Column width="60%">
            <Info
                className="notranslate"
              color={styles.header_secondText_color}
              fontFamily={styles.header_secondText_fontFamily}
              fontSize={styles.header_secondText_fontSize}
              fontStyle={styles.header_secondText_fontStyle}
              fontWeight={styles.header_secondText_fontWeight}
              textDecoration={styles.header_secondText_textDecoration}
            >
              {car ? car.name : ""}
            </Info>
          </Column>
        </Row>
        <Row>
          <Column width="40%">
            <Label
              color={styles.header_mainText_color}
              fontFamily={styles.header_mainText_fontFamily}
              fontSize={styles.header_mainText_fontSize}
              fontStyle={styles.header_mainText_fontStyle}
              fontWeight={styles.header_mainText_fontWeight}
              textDecoration={styles.header_mainText_textDecoration}
            >
              VIN-номер
            </Label>
          </Column>
          <Column width="60%">
            <Info
                className="notranslate"
              color={styles.header_secondText_color}
              fontFamily={styles.header_secondText_fontFamily}
              fontSize={styles.header_secondText_fontSize}
              fontStyle={styles.header_secondText_fontStyle}
              fontWeight={styles.header_secondText_fontWeight}
              textDecoration={styles.header_secondText_textDecoration}
            >
              {car ? car.vin : ""}
            </Info>
          </Column>
        </Row>
        {isFullOrderLegalNamePrimary && companyLegalName
          ? <Row>
            <Column width="40%">
              <Label
              color={styles.header_mainText_color}
              fontFamily={styles.header_mainText_fontFamily}
              fontSize={styles.header_mainText_fontSize}
              fontStyle={styles.header_mainText_fontStyle}
              fontWeight={styles.header_mainText_fontWeight}
              textDecoration={styles.header_mainText_textDecoration}
              >
                Юр.лицо
              </Label>
            </Column>
            <Column width="60%">
              <Info
              color={styles.header_secondText_color}
              fontFamily={styles.header_secondText_fontFamily}
              fontSize={styles.header_secondText_fontSize}
              fontStyle={styles.header_secondText_fontStyle}
              fontWeight={styles.header_secondText_fontWeight}
              textDecoration={styles.header_secondText_textDecoration}
              >
                {companyLegalName}
              </Info>
            </Column>
          </Row>
          : <Row>
            <Column width="40%">
              <Label
                color={styles.header_mainText_color}
                fontFamily={styles.header_mainText_fontFamily}
                fontSize={styles.header_mainText_fontSize}
                fontStyle={styles.header_mainText_fontStyle}
                fontWeight={styles.header_mainText_fontWeight}
                textDecoration={styles.header_mainText_textDecoration}
              >
                Сервисный центр
              </Label>
            </Column>
            <Column width="60%">
              <Info
                color={styles.header_secondText_color}
                fontFamily={styles.header_secondText_fontFamily}
                fontSize={styles.header_secondText_fontSize}
                fontStyle={styles.header_secondText_fontStyle}
                fontWeight={styles.header_secondText_fontWeight}
                textDecoration={styles.header_secondText_textDecoration}
              >
                {dealer ? dealer.name : "BMW MOTORRAD"}
              </Info>
            </Column>
          </Row>}
        {diagnosticsTemplate && (
          <RowLabels>
            <ColumnLabels width="20%">
              <img
                src={require("../../assets/done_icon.svg")}
                alt="хорошо"
                style={{ marginRight: "10px" }}
              />
              Хорошо
            </ColumnLabels>
            <ColumnLabels width="30%">
              <img
                src={require("../../assets/iconAlert.svg")}
                alt="внимание"
                style={{ marginRight: "10px" }}
              />
              Требует внимания
            </ColumnLabels>
            <ColumnLabels width="50%">
              <img
                src={require("../../assets/iconError.svg")}
                alt="замена"
                style={{ marginRight: "10px" }}
              />
              Необходима замена или ремонт/доливка
            </ColumnLabels>
          </RowLabels>
        )}
      </HeaderInfo>
    </Header>
  );
};

export default OrderHeader;
