import styled from "@emotion/styled";

export const Wrapper = styled.div`
  padding: 0 133px 0px 133px;
  margin-bottom: 44px;
  @media (max-width: 650px) {
    padding: 0 15px 0 15px;
  }
`;
export const Title = styled.div`
  padding: 30px 0 15px 0;
  ${
    "" /* TODO: сейчачс в paymentSection приходит пустой массив
          если нужно будет поправить жирность, то удалить замоканый стиль
   */
  }
  ${
    "" /* font-weight: ${({ fontWeight }) => (fontWeight ? "bold" : "normal")}; */
  }
  font-weight:bold;
  font-style: ${(props) => (props.fontStyle ? "italic" : "normal")};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "20px")};
  font-family: ${(props) => props.fontFamily};
  text-decoration: ${(props) => (props.textDecoration ? "underline" : "none")};
  color: ${(props) => props.color};
  line-height: 26px;
  margin-bottom: 30px;
`;
export const PriceList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  border-bottom: 1px dashed #b9b9b9;
`;
export const PaidInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
`;
export const PriceItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 7px;
`;
export const ImageContainer = styled.img`
  max-width: ${(props) => props.maxSize}%;
  padding: ${({padding}) => padding ? `0px ${padding}px` : "0px" };
  object-fit: contain;
`;
export const SmallText = styled.span`
  font-weight: 300;
  font-size: 10px;
  color: #000000;
  line-height: 156%;

  @media (max-width: 650px) {
    font-size: 8px;
  }
`;
export const SberInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  @media (max-width: 650px) {
    margin-top: 20px;
  }
`;
export  const PaymentsImages = styled.div`
  padding: 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 650px) {
    padding: 9px 0;
  }
`;
export const GreyText = styled.span`
  font-weight: ${({ fontWeight }) => (fontWeight ? "bold" : "400")};
  font-style: ${({ fontStyle }) => (fontStyle ? "italic" : "normal")};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "16px")};
  font-family: ${({ fontFamily }) => fontFamily};
  text-decoration: ${({ textDecoration }) =>
    textDecoration ? "underline" : "none"};
  color: ${({ color }) => color};
  line-height: 18px;
`;
export const GreyTextPrice = styled.span`
  font-weight: ${({ fontWeight }) => (fontWeight ? "bold" : "300")};
  font-style: ${({ fontStyle }) => (fontStyle ? "italic" : "normal")};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "15px")};
  font-family: ${({ fontFamily }) => fontFamily};
  text-decoration: ${({ textDecoration }) =>
    textDecoration ? "underline" : "none"};
  color: ${({ color }) => color};
  line-height: 18px;
`;

export const FinalPrice = styled.span`
  font-weight: ${({ fontWeight }) => (fontWeight ? "bold" : "normal")};
  font-style: ${({ fontStyle }) => (fontStyle ? "italic" : "normal")};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "17px")};
  font-family: ${({ fontFamily }) => fontFamily};
  text-decoration: ${({ textDecoration }) =>
    textDecoration ? "underline" : "none"};
  color: ${({ color }) => color};
  line-height: 24px;
  margin-top: 6px;
`;

export const MasterInfo = styled.div`
  display: flex;
  margin-top: 24px;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  max-width: 370px;
  width: 100%;
`;
export const ConfirmButton = styled.button`
  background: ${(props) => (props.buttonColor ? props.buttonColor : "#772AF3")};
  border-radius: 30px;
  color: #fff;
  padding: 15px 0;
  border: none;
  width: 100%;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:disabled {
    background: #868a91;
    color: red;
  }

  @media (max-width: 600px) {
    padding: 15px 0;
  }
`;

export const Main = styled.main`
  flex: 1;
  width: 100%;
  background: ${(props) => props.background};
`;
export const Label = styled.div`
  display: flex;
  cursor: pointer;
  align-items: flex-start;
  padding-bottom: 10px;
`;
export const Information = styled.div`
  display: flex;
  width: 100%;
  margin-left: 8px;
  justify-content: space-between;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 11px;
  align-items: center;
`;

export const Items = styled.ul`
  display: ${(props) => (props.isHidden ? "none" : "block")};
`;

export const Span = styled.span`
  font-weight: ${({ fontWeight }) => (fontWeight ? "bold" : "normal")};
  font-style: ${({ fontStyle }) => (fontStyle ? "italic" : "normal")};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "17px")};
  font-family: ${({ fontFamily }) => fontFamily};
  text-decoration: ${({ textDecoration }) =>
    textDecoration ? "underline" : "none"};
  color: ${({ color }) => color};
`;
export const BtnGroup = styled.div`
  display: flex;
  justify-content: center;
  & > div:first-child {
    margin-right: 26px;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    & > div:first-child {
      margin-right: 0;
    }
  }
`;

