import React from "react";
import styled from "@emotion/styled";

const Item = styled.li`
  background: ${(props) => props.color};
  border-radius: 3px;
  padding: 6px 10px;
  margin-bottom: 5px;
`;

const Label = styled.label`
  display: flex;
`;

const Information = styled.div`
  display: flex;
  width: 100%;
  margin-left: 8px;
  justify-content: space-between;
  align-items: center;
`;

const Span = styled.span`
  font-weight: ${(props) => (props.fontWeight ? "bold" : "normal")};
  font-style: ${(props) => (props.fontStyle ? "italic" : "normal")};
  font-size: ${(props) => `${props.fontSize}px`};
  font-family: ${(props) => props.fontFamily};
  text-decoration: ${(props) => (props.textDecoration ? "underline" : "none")};
  color: ${(props) => props.color};
`;

const Card = ({ color, isRelatedGoods, styles,currentCurrency, ...props }) => {
  return (
    <Item
      color={
        styles.main_action_card_background_color ||
        styles.main_recommendation_card_background_color
      }
    >
      <Label>
        <Information>
          <Span
            style={{ width: "60%" }}
            className="notranslate"
            color={
              styles.main_action_card_title_color ||
              styles.main_recommendation_card_title_color ||
              (isRelatedGoods && styles.main_relatedProducts_item_title_color)
            }
            fontFamily={
              styles.main_action_card_title_fontFamily ||
              styles.main_recommendation_card_title_fontFamily ||
              (isRelatedGoods &&
                styles.main_relatedProducts_item_title_fontFamily)
            }
            fontSize={
              styles.main_action_card_title_fontSize ||
              styles.main_recommendation_card_title_fontSize ||
              (isRelatedGoods &&
                styles.main_relatedProducts_item_title_fontSize)
            }
            fontStyle={
              styles.main_action_card_title_fontStyle ||
              styles.main_recommendation_card_title_fontStyle ||
              (isRelatedGoods &&
                styles.main_relatedProducts_item_title_fontStyle)
            }
            fontWeight={
              styles.main_action_card_title_fontWeight ||
              styles.main_recommendation_card_title_fontWeight ||
              (isRelatedGoods &&
                styles.main_relatedProducts_item_title_fontWeight)
            }
            textDecoration={
              styles.main_action_card_title_textDecoration ||
              styles.main_recommendation_card_title_textDecoration ||
              (isRelatedGoods &&
                styles.main_relatedProducts_item_title_textDecoration)
            }
          >
            {props?.name}
          </Span>
          <Span
            color={
              styles.main_action_card_price_color ||
              styles.main_recommendation_card_price_color ||
              (isRelatedGoods && styles.main_relatedProducts_item_price_color)
            }
            fontFamily={
              styles.main_action_card_price_fontFamily ||
              styles.main_recommendation_card_price_fontFamily ||
              (isRelatedGoods &&
                styles.main_relatedProducts_item_price_fontFamily)
            }
            fontSize={
              styles.main_action_card_price_fontSize ||
              styles.main_recommendation_card_price_fontSize ||
              (isRelatedGoods &&
                styles.main_relatedProducts_item_price_fontSize)
            }
            fontStyle={
              styles.main_action_card_price_fontStyle ||
              styles.main_recommendation_card_price_fontStyle ||
              (isRelatedGoods &&
                styles.main_relatedProducts_item_price_fontStyle)
            }
            fontWeight={
              styles.main_action_card_price_fontWeight ||
              styles.main_recommendation_card_price_fontWeight ||
              (isRelatedGoods &&
                styles.main_relatedProducts_item_price_fontWeight)
            }
            textDecoration={
              styles.main_action_card_price_textDecoration ||
              styles.main_recommendation_card_price_textDecoration ||
              (isRelatedGoods &&
                styles.main_relatedProducts_item_price_textDecoration)
            }
          >
            {parseFloat(props?.price).toFixed(2)}{" "}
            <span className="price notranslate">{currentCurrency} </span>
          </Span>
        </Information>
      </Label>
    </Item>
  );
};

export default Card;
