import React, { useEffect, useState } from 'react'
import {
  CodeWrapper,
  Title,
  Wrapper,
  Description,
  ImageContainer,
} from './CloseOrder.style'

const CloseOrder = () => {
  return (
    <Wrapper>
      <CodeWrapper>
        <ImageContainer src={require('../../assets/close_pic.png')} />
        <Title>Заказ-наряд закрыт {localStorage.getItem('dateClose')}</Title>
        <Description>
          Для получения подробной информации, обратитесь в сервис по месту
          ремонта автомобиля
          <span> {localStorage.getItem('dealershipName')}</span>
        </Description>
      </CodeWrapper>
    </Wrapper>
  )
}

export default CloseOrder
