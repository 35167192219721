import React from "react";
import { useEffect, useState, useMemo } from "react";
import useModal from "../../hooks/useModal";
import Card from "../Card";
import {
  Main,
  Wrapper,
  Title,
  Label,
  Information,
  Span,
  Items,
  PriceItem,
  PriceList,
  GreyText,
  GreyTextPrice,
  FinalPrice,
  ButtonWrapper,
  ConfirmButton,
  BtnGroup, SmallText, SberInfo, PaymentsImages, ImageContainer,
} from "./PaymentSection.styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import classes from "./PaymentSection.module.css";
import Modal from "../ModalPay/ModalPay";
import {
  MAX_PRICE,
  MIN_PRICE_VSEGDADA,
  MODAL_TYPE_PAY_CREDIT,
  MODAL_TYPE_PAY_ONLINE,
  STATUS_PAY_1C,
  STATUS_PAY_PLAN_TINKOFF,
  STATUS_PAY_PLAN_VSEGDADA,
  STATUS_PAY_SBERBANK,
  STATUS_PAY_SBP,
  STATUS_PAY_TINKOFF,
  tinkoffCreditStatuses,
  TINKOFF_CREDIT_STATUS,
  vsegdaDaStatuses,
  VSEGDADA_CREDIT_STATUS,
} from "../../utils/constants";
import ModalPayOnline from "../ModalPay/ModalPayOnline";
import {getCurrency} from "../../utils/helpers";

const PaymentSection = ({
  recommendations,
  fixedWork,
  styles, currency,
  sendPaymentRequest,
  dealerResultCompany,
  sbpUrl,
                          orderNumber
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingSBP, setLoadingSBP] = useState(false);
  const [loadingVsegdaDA, setLoadingVsegdaDa] = useState(false);
  const [loadingSberbank, setLoadingSberbank] = useState(false);
  const [recommendedPrice, setRecommendedPrice] = useState(0);
  const [relatedGoodsPrice, setRelatedGoodsPrice] = useState(0);
  const [fixedWorkPrice, setFixedWorkPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPaidPrice, setTotalPaidPrice] = useState(0);
  const [isHiddenDetails, setHiddenDetails] = useState(true);
  const [isHideRelativeGoods, setIsHideRelativeGoods] = useState(true);
  const { toggle, visible, visiblePayOnline } = useModal();
  const currentCurrency = getCurrency(currency?.symbol)
  const parsedWorks = useMemo(() => {
    const parsedWorks = [];
    for (const el of recommendations) {
      if (el.status === "confirmed") {
        parsedWorks.push(
          el.repairItem.map((item) =>
            el.isPaid ? { ...item, status: "(Оплачено)" } : { ...item }
          )
        );
        parsedWorks.push(
          el.repairWork.map((item) =>
            el.isPaid ? { ...item, status: "(Оплачено)" } : { ...item }
          )
        );
      }
    }
    if (fixedWork?.length) {
      fixedWork.forEach((el) => {
        parsedWorks.push(
          el.repairItem.map((item) =>
            el.isPaid ? { ...item, status: "(Оплачено)" } : { ...item }
          )
        );
        parsedWorks.push(
          el.repairWork.map((item) =>
            el.isPaid ? { ...item, status: "(Оплачено)" } : { ...item }
          )
        );
      });
    }
    return parsedWorks.flat();
  }, [recommendations, fixedWork]);
  const isAllPaid = useMemo(()=>{
    return parsedWorks?.every(work => work.status === '(Оплачено)')
  },[parsedWorks])
  const checkHasTinkoffStatus = (status) => {
    return localStorage.getItem(TINKOFF_CREDIT_STATUS) === status;
  };
  const checkHasVsegdaDaStatus = (status) => {
    return localStorage.getItem(VSEGDADA_CREDIT_STATUS) === status;
  };

  const onPayClick = async() => {
    if(sbpUrl){
      toggle(MODAL_TYPE_PAY_ONLINE);
    } else if (dealerResultCompany?.endpointForCustomWebhook) {
      pay(STATUS_PAY_1C);
    } else if (!dealerResultCompany?.hasSber) {
      pay(STATUS_PAY_TINKOFF);
    } else if (!dealerResultCompany?.hasTinkoff) {
      pay(STATUS_PAY_SBERBANK);
    } else {
      toggle(MODAL_TYPE_PAY_ONLINE);
    }
  };

  const pay = async (status) => {
    if (status === STATUS_PAY_PLAN_TINKOFF || status === STATUS_PAY_TINKOFF) {
      setLoading(true);
    } else if (status === STATUS_PAY_PLAN_VSEGDADA) {
      setLoadingVsegdaDa(true);
    } else if (status === STATUS_PAY_SBERBANK) {
      setLoadingSberbank(true);
    }else if (status === STATUS_PAY_SBP) {
      setLoadingSBP(true);
    }

    await sendPaymentRequest(status);

    setLoading(false);
    setLoadingSBP(false);
    setLoadingVsegdaDa(false);
    setLoadingSberbank(false);
  };

  const toggleDetails = () => {
    setHiddenDetails(!isHiddenDetails);
  };

  useEffect(() => {
    const defaultValue = {notPaid:0,paid:0}
    const fixedPrice = fixedWork?.reduce((acc,item) => {
      if (!item.isPaid) {
        acc.notPaid += parseFloat(item.price);
      }else{
        acc.paid += parseFloat(item.price);
      }
      return acc
    },{notPaid:0,paid:0}) || defaultValue
    const recommendationsPrice = recommendations?.reduce((acc, el) => {
      if (!el.isPaid && el.status === "confirmed" && !el.isRelatedGoods) {
        acc.notPaid += parseFloat(el.price);
      }else if(el.isPaid && el.status === "confirmed" && !el.isRelatedGoods){
        acc.paid += parseFloat(el.price);
      }
      return acc;
    },{notPaid:0,paid:0}) || defaultValue
    const relatedGoodsPrice = recommendations?.reduce((acc, el) => {
      if (el.isPaid && el.status === "confirmed" && el.isRelatedGoods) {
        acc.paid += parseFloat(el.price);
      }else if(!el.isPaid && el.status === "confirmed" && el.isRelatedGoods){
        acc.notPaid += parseFloat(el.price);
      }
      return acc;
    },{notPaid:0,paid:0}) || defaultValue
      const totalPricePaid = fixedPrice.paid + recommendationsPrice.paid + relatedGoodsPrice.paid
    const totalPrice = fixedPrice.notPaid + recommendationsPrice.notPaid + relatedGoodsPrice.notPaid

    setTotalPaidPrice(totalPricePaid)
    setTotalPrice(totalPrice);
    setRelatedGoodsPrice(relatedGoodsPrice.notPaid);
    setRecommendedPrice(recommendationsPrice.notPaid);
    setFixedWorkPrice(fixedPrice.notPaid);
  }, []);
  return (
    <Main background={styles.main_background_color}>
      <Modal
        loadingVsegdaDA={loadingVsegdaDA}
        loading={loading}
        totalPrice={totalPrice}
        dealerResultCompany={dealerResultCompany}
        pay={pay}
        visible={visible}
        toggle={toggle}
      />
      <ModalPayOnline
        loadingSberbank={loadingSberbank}
        loadingSBP={loadingSBP}
        loading={loading}
        totalPrice={totalPrice}
        visible={visiblePayOnline}
        dealerResultCompany={dealerResultCompany}
        toggle={toggle}
        pay={pay}
        sbpUrl={sbpUrl}
      />
      <Wrapper>
        <React.Fragment>
          <Title>{isAllPaid ? "Ваш Заказ-наряд успешно оплачен" : "Все работы по заказ-наряду выполнены"}</Title>
          <>
            <Label>
              <Information onClick={toggleDetails}>
                <Span
                  style={{ width: "60%" }}
                  color={
                    styles.main_action_title_color ||
                    styles.main_action_item_title_color
                  }
                  fontFamily={
                    styles.main_action_title_fontFamily ||
                    styles.main_action_item_title_fontFamily
                  }
                  fontSize={
                    styles.main_action_title_fontSize ||
                    styles.main_action_item_title_fontSize
                  }
                  fontStyle={
                    styles.main_action_title_fontStyle ||
                    styles.main_action_item_title_fontStyle
                  }
                  fontWeight={
                    styles.main_action_title_fontWeight ||
                    styles.main_action_item_title_fontWeight
                  }
                  textDecoration={
                    styles.main_action_title_textDecoration ||
                    styles.main_action_item_title_textDecoration
                  }
                >
                  {"Детали заказ-наряда"}
                </Span>
                <Span>
                  <GreyText
                    color={
                      styles.main_recommendation_title_color ||
                      styles.main_action_item_title_color
                    }
                    fontFamily={
                      styles.main_recommendation_title_fontFamily ||
                      styles.main_action_item_title_fontFamily
                    }
                    fontSize={
                      styles.main_recommendation_title_fontSize ||
                      styles.main_action_item_title_fontSize
                    }
                    fontStyle={
                      styles.main_recommendation_title_fontStyle ||
                      styles.main_action_item_title_fontStyle
                    }
                    fontWeight={
                      styles.main_recommendation_title_fontWeight ||
                      styles.main_action_item_title_fontWeight
                    }
                    textDecoration={
                      styles.main_recommendation_title_textDecoration ||
                      styles.main_action_item_title_textDecoration
                    }
                  >
                    {(totalPrice + totalPaidPrice).toFixed(2)}
                    <span className="price">{currentCurrency}</span>
                  </GreyText>
                  <img
                    src={
                      isHiddenDetails
                        ? require("../../assets/bottom.svg")
                        : require("../../assets/top.svg")
                    }
                    style={{ marginLeft: "11px" }}
                    alt="icon"
                  />
                </Span>
              </Information>
            </Label>
            <Items isHidden={isHiddenDetails}>
              {parsedWorks?.map((el) => (
                <Card
                  key={"work"}
                  currentCurrency={currentCurrency}
                  color={styles.page_colors_recommedations}
                  styles={styles}
                  {...el}
                />
              ))}
            </Items>
          </>
        </React.Fragment>
        <PriceList>
          <PriceItem>
            <GreyText
              color={styles.main_total_relatedProducts_title_color}
              fontFamily={styles.main_total_relatedProducts_title_fontFamily}
              fontSize={styles.main_total_relatedProducts_title_fontSize}
              fontStyle={styles.main_total_relatedProducts_title_fontStyle}
              fontWeight={styles.main_total_relatedProducts_title_fontWeight}
              textDecoration={
                styles.main_total_relatedProducts_title_textDecoration
              }
            >
              Сопутствующие товары
            </GreyText>
            <GreyText
              color={styles.main_total_relatedProducts_price_color}
              fontFamily={styles.main_total_relatedProducts_price_fontFamily}
              fontSize={styles.main_total_relatedProducts_price_fontSize}
              fontStyle={styles.main_total_relatedProducts_price_fontStyle}
              fontWeight={styles.main_total_relatedProducts_price_fontWeight}
              textDecoration={
                styles.main_total_relatedProducts_price_textDecoration
              }
            >
              {relatedGoodsPrice ? relatedGoodsPrice?.toFixed(2) : "0.00"}{" "}
              <span className="price">{currentCurrency}</span>
            </GreyText>
          </PriceItem>
          <PriceItem>
            <GreyText
              color={styles.main_total_recommendations_title_color}
              fontFamily={styles.main_total_recommendations_title_fontFamily}
              fontSize={styles.main_total_recommendations_title_fontSize}
              fontStyle={styles.main_total_recommendations_title_fontStyle}
              fontWeight={styles.main_total_recommendations_title_fontWeight}
              textDecoration={
                styles.main_total_recommendations_title_textDecoration
              }
            >
              Рекомендации
            </GreyText>
            <GreyText
              color={styles.main_total_recommendations_price_color}
              fontFamily={styles.main_total_recommendations_price_fontFamily}
              fontSize={styles.main_total_recommendations_price_fontSize}
              fontStyle={styles.main_total_recommendations_price_fontStyle}
              fontWeight={styles.main_total_recommendations_price_fontWeight}
              textDecoration={
                styles.main_total_recommendations_price_textDecoration
              }
            >
              {recommendedPrice ? recommendedPrice?.toFixed(2) : "0.00"}{" "}
              <span className="price">{currentCurrency}</span>
            </GreyText>
          </PriceItem>
          {!!totalPrice && (
              <PriceItem>
                <FinalPrice
                    color={styles.main_total_title_color}
                    fontFamily={styles.main_total_title_fontFamily}
                    fontSize={styles.main_total_title_fontSize}
                    fontStyle={styles.main_total_title_fontStyle}
                    fontWeight={styles.main_total_title_fontWeight}
                    textDecoration={styles.main_total_title_textDecoration}
                >
                  Итого
                </FinalPrice>
                <FinalPrice
                    color={styles.main_total_price_color}
                    fontFamily={styles.main_total_price_fontFamily}
                    fontSize={styles.main_total_price_fontSize}
                    fontStyle={styles.main_total_price_fontStyle}
                    fontWeight={styles.main_total_price_fontWeight}
                    textDecoration={styles.main_total_price_textDecoration}
                >
                  {totalPrice ? totalPrice.toFixed(2) : "0.00"}
                  <span className="price"> {currentCurrency}</span>
                </FinalPrice>
              </PriceItem>
          )}
          <PriceItem>
            <FinalPrice
                color={styles.main_total_title_color}
                fontFamily={styles.main_total_title_fontFamily}
                fontSize={styles.main_total_title_fontSize}
                fontStyle={styles.main_total_title_fontStyle}
                fontWeight={styles.main_total_title_fontWeight}
                textDecoration={styles.main_total_title_textDecoration}
            >
              Итого оплачено
            </FinalPrice>
            <FinalPrice
                color={styles.main_total_price_color}
                fontFamily={styles.main_total_price_fontFamily}
                fontSize={styles.main_total_price_fontSize}
                fontStyle={styles.main_total_price_fontStyle}
                fontWeight={styles.main_total_price_fontWeight}
                textDecoration={styles.main_total_price_textDecoration}
            >
              {totalPaidPrice ? totalPaidPrice.toFixed(2) : "0.00"}
              <span className="price"> {currentCurrency}</span>
            </FinalPrice>
          </PriceItem>
        </PriceList>
        {totalPrice !== parseFloat(0) &&
        !isNaN(totalPrice) &&
        !checkHasTinkoffStatus(tinkoffCreditStatuses[1].approved) &&
        !checkHasTinkoffStatus(tinkoffCreditStatuses[2].signed) &&
        !checkHasVsegdaDaStatus(vsegdaDaStatuses.FINALLY_APPROVED) &&
        !checkHasVsegdaDaStatus(vsegdaDaStatuses.APPROVED) &&
        !checkHasVsegdaDaStatus(vsegdaDaStatuses.SHOP_APPROVED) &&
        !checkHasVsegdaDaStatus(vsegdaDaStatuses.WAIT_APPROVE) &&
        !checkHasVsegdaDaStatus(vsegdaDaStatuses.CANCELED) &&
        !checkHasVsegdaDaStatus(vsegdaDaStatuses.SIGNED) &&
        !checkHasVsegdaDaStatus(vsegdaDaStatuses.ACTIVE) ? (
          <>
            {checkHasTinkoffStatus(tinkoffCreditStatuses.rejected) && (
              <div className={classes.text__rejected}>
                Вам было отказано в кредите
              </div>
            )}
            {checkHasVsegdaDaStatus(vsegdaDaStatuses.REJECTED) && (
              <div className={classes.text__rejected}>
                Заявка по ВсегдаДа отклонена
              </div>
            )}
            {!isAllPaid && (
                <BtnGroup>
                  <ButtonWrapper>
                    <ConfirmButton
                        buttonColor={styles.main_pay_button_background_color}
                        onClick={onPayClick}
                        startIcon={loading && <CircularProgress size={10} />}
                        disabled={loading}
                    >
                      <GreyText
                          color={styles.main_pay_button_color}
                          fontFamily={styles.main_pay_button_fontFamily}
                          fontSize={styles.main_pay_button_fontSize}
                          fontStyle={styles.main_pay_button_fontStyle}
                          fontWeight={styles.main_pay_button_fontWeight}
                          textDecoration={styles.main_pay_button_textDecoration}
                      >
                        Оплатить онлайн
                      </GreyText>
                    </ConfirmButton>
                  </ButtonWrapper>
                  {totalPrice >= parseFloat(MIN_PRICE_VSEGDADA) &&
                  totalPrice <= parseFloat(MAX_PRICE) &&
                  dealerResultCompany.shopId &&
                  dealerResultCompany.showcaseId && (
                      <ButtonWrapper>
                        <ConfirmButton
                            buttonColor={styles.main_payPlan_button_background_color}
                            onClick={() => toggle(MODAL_TYPE_PAY_CREDIT)}
                            disabled={loading}
                        >
                          <GreyText
                              color={styles.main_payPlan_button_color}
                              fontFamily={styles.main_payPlan_button_fontFamily}
                              fontSize={styles.main_payPlan_button_fontSize}
                              fontStyle={styles.main_payPlan_button_fontStyle}
                              fontWeight={styles.main_payPlan_button_fontWeight}
                              textDecoration={styles.main_payPlan_button_textDecoration}
                          >
                            Оформить рассрочку
                          </GreyText>
                        </ConfirmButton>
                      </ButtonWrapper>
                  ) }
                </BtnGroup>
            )}
          </>
        ) : null}
        {dealerResultCompany.hasSber  && (
            <SberInfo>
              <SmallText>
                Для выбора оплаты с помощью банковской карты нажмите на кнопку «Оплатить онлайн». Оплата проходит через ПАО СБЕРБАНК с использованием Банковских карт следующих платежных систем:
              </SmallText>
              <PaymentsImages>
                <ImageContainer
                    maxSize="8"
                    src={
                      require("../../assets/mir-logo.png")
                    }
                />
                <ImageContainer
                    maxSize="8"
                    padding={30}
                    src={
                      require("../../assets/visa-logo.png")
                    }
                />
                <ImageContainer
                    maxSize="8"
                    src={
                      require("../../assets/master-logo.png")
                    }
                />
              </PaymentsImages>
              <SmallText>
                Размер и сроки возврата товара, стоимости работ/услуг определяются согласно Закону РФ от 07.02.1992 №2300-1 «О защите прав потребителей»
              </SmallText>
            </SberInfo>
        )}
        {/* Tinkoffff */}
        {checkHasTinkoffStatus(tinkoffCreditStatuses[1].approved) && (
          <div className={classes.text__approved}>
            Ваш кредит подтвержден, ожидается подписание документов
          </div>
        )}
        {checkHasTinkoffStatus(tinkoffCreditStatuses[2].signed) && (
          <div className={classes.text__approved}>Заказ-наряд оплачен</div>
        )}
        {/* VsegdaDa */}
        {checkHasVsegdaDaStatus(vsegdaDaStatuses.FINALLY_APPROVED) && (
          <div className={classes.text__approved}>Заявка ВсегдаДа одобрена</div>
        )}
        {checkHasVsegdaDaStatus(vsegdaDaStatuses.APPROVED) && (
          <div className={classes.text__approved}>Заявка на рассмотрении</div>
        )}
        {checkHasVsegdaDaStatus(vsegdaDaStatuses.SHOP_APPROVED) && (
          <div className={classes.text__approved}>Заявка на рассмотрении</div>
        )}
        {checkHasVsegdaDaStatus(vsegdaDaStatuses.WAIT_APPROVE) && (
          <div className={classes.text__approved}>Заявка на рассмотрении</div>
        )}
        {checkHasVsegdaDaStatus(vsegdaDaStatuses.CANCELED) && (
          <div className={classes.text__approved}>Заявка на рассмотрении</div>
        )}
        {checkHasVsegdaDaStatus(vsegdaDaStatuses.SIGNED) && (
          <div className={classes.text__approved}>Заявка на рассмотрении</div>
        )}
        {checkHasVsegdaDaStatus(vsegdaDaStatuses.ACTIVE) && (
          <div className={classes.text__approved}>Заявка на рассмотрении</div>
        )}
      </Wrapper>
    </Main>
  );
};

export default PaymentSection;
