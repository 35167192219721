import React, { useEffect, useState } from 'react'
import {
  CodeWrapper,
  Title,
  Wrapper,
  Description,
  ImageContainer, ReturnButton,
} from './SuccessPayment.styles'
import { getFullOrder } from '../../utils/data'
import {Link} from "wouter";

const SuccessPayment = () => {
  const [number, setNumber] = useState()
  const [externalId,setExternalId] = useState('')
  const id = window.location.href.split('id=')[1]
  useEffect(() => {
    ;(async () => {
      try {
        const response = await getFullOrder(id)
        setNumber(response.data.repairName)
        setExternalId(response.data.externalId)
      } catch (e) {
        console.error(e)
      }
    })()
  }, [])

  return (
    <Wrapper>
      <CodeWrapper>
        <ImageContainer src={require('../../assets/succ_icon.svg')} />
        <Title>Заказ-наряд оплачен!</Title>
        <Description>
          Спасибо, оплата прошла. Платёж по заказ-наряду
          <span> № {number || localStorage.getItem('repairName')}</span> прошёл
          успешно.
        </Description>
       <Link href={`/order/${externalId}?access=free_grant`}>
         <ReturnButton>
           Вернуться к заказ-наряду
         </ReturnButton>
       </Link>
      </CodeWrapper>
    </Wrapper>
  )
}

export default SuccessPayment
