import { months } from "./constants";
import api from "../utils/api";
export const getFormatedDate = (val) => {
  let message = "";
  let newDate = new Date(val);
  let year = newDate.getFullYear();
  let month = newDate.getMonth();
  let day = newDate.getDate();
  let hour = newDate.getHours();
  let minute = newDate.getMinutes();
  message += `${day} ${months[month]} ${year}, ${twoDigitPad(
    hour
  )}:${twoDigitPad(minute)}`;
  return message;
};

export const toDataUrl = async function (url, callback) {
  //Convert to base64
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.onerror = () => {
      reject({
        status: this.status,
        statusText: xhr.statusText,
      });
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  });
};

export const getChecklistDate = (value) => {
  let message = "";
  let year = value.substr(0, 4);
  let month = value.slice(5, 7);
  let day = value.slice(8, 10);
  message += `${day}.${month}.${year}`;
  return message;
};

const twoDigitPad = (num) => {
  return num < 10 ? "0" + num : num;
};

export function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export const getCurrency = (currency) => currency || "₽";

export const getFonts = async (styles) => {
  const basePath = `https://fonts.googleapis.com/css?subset=cyrillic&family=`;
  const keys = Object.keys(styles).filter((key) => key.includes("fontFamily"));
  const fonts = Array.from(
    new Set(keys.map((key) => styles[key].split(" ").join("+")))
  );
  const header = document.querySelector("head");
  for await (const font of fonts) {
    const link = document.createElement("link");
    link.setAttribute("href", basePath + font);
    link.setAttribute("rel", "stylesheet");
    header.insertAdjacentElement("beforeend", link);
  }
};
export const getMaskedNumber = (number) => {
  if (number.length === 11) {
    const nums = number.replace(/[^\d]/g, "");
    const arrayNumbers = nums.match(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/);
    return `${arrayNumbers[1]} (${arrayNumbers[2]}) ***-**-${arrayNumbers[5]}`;
  }
  return number;
};
