import React from 'react'
import { useLocation } from 'wouter'
import {
  CodeWrapper,
  Title,
  ButtonWrapper,
  ConfirmButton,
  Wrapper,
  ReasonText,
  ImageContainer,
} from './NotFoundOrder.styles'

const NotFoundOrder = () => {
  const [, setLocation] = useLocation()

  const handleClick = () => {
    setLocation('/confirmation')
  }

  return (
    <Wrapper>
      <CodeWrapper>
        <ImageContainer src={require('../../assets/neutral-icon.png')} />
        <Title>Такой страницы не существует.</Title>
        <ReasonText>Введен неверный или недоступный код страницы.</ReasonText>
        <ButtonWrapper>
          <ConfirmButton onClick={handleClick}>Повторить</ConfirmButton>
        </ButtonWrapper>
      </CodeWrapper>
    </Wrapper>
  )
}

export default NotFoundOrder
