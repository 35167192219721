import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from "wouter";
import OrderItem from "../OrderItem";
import { CheckListTitle } from "./CheckListTitle";
import { Checkbox, ModalConfirm } from "../";
import styled from "@emotion/styled";
import request from "../../utils/api";
import { titleStyles } from "./helper";
import CardRelatedProducts from "../Card/CardRelated";
import useModal from "../../hooks/useModal";
import { FILES_URL, MODAL_TYPE_CONFIRM_ORDER } from "../../utils/constants";
import { getCurrency } from "../../utils/helpers";
import { notifyError } from "../../utils/notify";

const Wrapper = styled.div`
  padding: 0px 133px 0px 133px;
  margin: 30px 0px 44px;
  @media (max-width: 650px) {
    padding: 0 15px 0 15px;
  }
`;
const RelatedGoodsLabel = styled.p`
  font-weight: ${({ fontWeight }) => (fontWeight ? "bold" : "normal")};
  font-style: ${(props) => (props.fontStyle ? "italic" : "normal")};
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : "20px")};
  font-family: ${(props) => props.fontFamily};
  text-decoration: ${(props) => (props.textDecoration ? "underline" : "none")};
  color: ${(props) => props.color};
  margin: 0 0 13px;
`;
export const RelatedProductsBlock = styled.div`
  padding: 30px 20px;
  margin-bottom: 40px;
  margin-top: 40px;
  border: 1px dashed ${({ border }) => border || "#772af3"};
  background: ${({ background }) => background || "#f9f6ff"};
    border-radius: 10px;
`;

const Title = styled.div`
  padding: 30px 0 15px 0;
  font-weight: ${(props) => (props.fontWeight ? "bold" : "normal")};
  font-style: ${(props) => (props.fontStyle ? "italic" : "normal")};
  font-size: ${(props) => `${props.fontSize}px`};
  font-family: ${(props) => props.fontFamily};
  text-decoration: ${(props) => (props.textDecoration ? "underline" : "none")};
  color: ${(props) => props.color};
  line-height: 26px;
`;

const PriceList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  border-bottom: 1px dashed #b9b9b9;
`;
const PriceItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 7px;
`;
const GreyText = styled.span`
  font-weight: ${(props) => (props.fontWeight ? "bold" : "normal")};
  font-style: ${(props) => (props.fontStyle ? "italic" : "normal")};
  font-size: ${(props) => `${props.fontSize}px`};
  font-family: ${(props) => props.fontFamily};
  text-decoration: ${(props) => (props.textDecoration ? "underline" : "none")};
  color: ${(props) => props.color || (props.isButton ? "#fff" : "#000")};
`;

const ContactText = styled.span`
  font-weight: ${(props) => (props.fontWeight ? "bold" : "normal")};
  font-style: ${(props) => (props.fontStyle ? "italic" : "normal")};
  font-family: ${(props) => props.fontFamily};
  text-decoration: ${(props) => (props.textDecoration ? "underline" : "none")};
  font-size: 12px;
  color: gray;
  margin-bottom: 5px;
  margin-left: 10px;
`;

const FinalPrice = styled.span`
  font-weight: ${(props) => (props.fontWeight ? "bold" : "normal")};
  font-style: ${(props) => (props.fontStyle ? "italic" : "normal")};
  font-size: ${(props) => `${props.fontSize}px`};
  font-family: ${(props) => props.fontFamily};
  text-decoration: ${(props) => (props.textDecoration ? "underline" : "none")};
  color: ${(props) => props.color};
  line-height: 24px;
  margin-top: 6px;
`;
const MasterInfo = styled.div`
  display: flex;
  margin-top: 24px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
`;
const ConfirmButton = styled.button`
  width: 72%;
  background: ${(props) => (props.buttonColor ? props.buttonColor : "#772AF3")};
  border-radius: 30px;
  color: ${(props) => props.textColor};
  padding: 15px 24px;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:disabled {
    background: #c7c7c7;
    cursor: not-allowed;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;
const ImgContainer = styled.img`
  width: 71px;
  height: 71px;
  object-fit: cover;
`;

const Link = styled.a`
  color: #772af3;
  cursor: pointer;
  text-decoration: none;
`;

const MasterContacts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin-left: 25px;
`;

const Contact = styled.div`
  margin-bottom: 5px;
`;

const AggrementWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px auto 0 auto;
  width: 80%;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const ContactInfo = styled.span`
  font-weight: ${(props) => (props.fontWeight ? "bold" : "normal")};
  font-style: ${(props) => (props.fontStyle ? "italic" : "normal")};
  font-size: ${(props) => `${props.fontSize}px`};
  font-family: ${(props) => props.fontFamily};
  text-decoration: ${(props) => (props.textDecoration ? "underline" : "none")};
  color: ${(props) => props.color};
  line-height: 18px;
  margin-left: 10px;
`;

const PaidInfo = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: 600;
`;

export const Signature = styled.img`
  position: absolute;
  width: 50px;
  left: 45px;
  top: 20px;
  object-fit: cover;
`;

const CheckList = ({
  setLoading,
  recommendations,
  fixedWork,
  worker,
  orderId,
  orderHistoryId,
  price,
  recommendPrice,
  relatedGoodsPrice,
  fixedPrice,
  styles,
  orderPdf,
  handleRecommendedWorkStatusChange,
  showControllers,
  orderNumber,
  customer,
  contractOffer,
  currency,
  withTheCode,
}) => {
  const [, setLocation] = useLocation();
  const [aggrement, setAggrement] = useState(true);
  const [offer, setOffer] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [disable, setDisable] = useState(true);
  const [aggrementError, setAggrementError] = useState(false);
  const [offerError, setOfferError] = useState(false);
  const [isAgreedOpen, setIsAgreedOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const roles = localStorage.getItem("permissions");
  const accessToken = localStorage.getItem("access_token");
  const isAdmin = roles?.includes("ROLE_ADMIN");
  const { toggle, visible } = useModal();
  const [isNeedAgreedOpen, setIsNeedAgreedOpen] = useState(
    styles.isNeedAgreedOpen ?? true
  );
  const [toFixed, setToFixed] = useState([]);
  const isConfirmedRecommendations = useMemo(
    () => recommendations.filter((el) => el.status === "confirmed"),
    [recommendations]
  );

  const notConfirmedRecommendations = useMemo(
    () => recommendations.filter((el) => el.status !== "confirmed"),
    [recommendations]
  );
  const isRelatedGoods = useMemo(
    () => notConfirmedRecommendations.some((el) => el.isRelatedGoods),
    [notConfirmedRecommendations]
  );
  const notRelatedGoods = useMemo(
    () => notConfirmedRecommendations.filter((el) => !el.isRelatedGoods),
    [notConfirmedRecommendations]
  );
  const currentCurrency = getCurrency(currency?.symbol);
  const isAutoConfirm = isAdmin && accessToken;

  useEffect(() => {
    if (fixedWork) {
      if (fixedWork.length && isConfirmedRecommendations.length) {
        setToFixed([...fixedWork, ...isConfirmedRecommendations]);
      } else if (fixedWork.length && !isConfirmedRecommendations.length) {
        setToFixed(fixedWork);
      } else if (!fixedWork.length && isConfirmedRecommendations.length) {
        setToFixed([...isConfirmedRecommendations]);
      } else {
        setToFixed(fixedWork);
      }
    } else {
      if (isConfirmedRecommendations.length) {
        setToFixed([...isConfirmedRecommendations]);
      }
    }
  }, [fixedWork]);

  const handleCheckCode = async (code) => {
    try {
      setIsLoading(true);
      const servicePackets = recommendations
        .filter((packet) => !packet.isPaid)
        .map((packet) => ({
          status: packet.deleted ? "rejected" : "confirmed",
          id: packet.id,
        }));
      await request({
        url: `/full_orders/${orderId}`,
        method: "PUT",
        data: {
          status: "completed",
          servicePackets,
          reconciliationCode: code,
          eventSource: "browser",
        },
      });
      setLocation("/success");
    } catch (e) {
      setError(e);
      if (isAutoConfirm) notifyError(e?.response?.data?.message);
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmClick = async () => {
    try {
      await request({
        url: `/full_orders/${orderId}/log_event`,
        method: "POST",
        data: {
          eventSource: "browser",
          eventData: "Клиент нажал на кнопку Согласовать ЗН",
          eventType: 6,
        },
      });
    } catch (e) {
      console.error(e);
    }
    if (isAutoConfirm || !withTheCode) {
      handleCheckCode();
    } else {
      toggle(MODAL_TYPE_CONFIRM_ORDER);
    }
  };

  const handleAggrementCheck = (event) => {
    setAggrement(event.target.checked);
    setAggrementError(false);
  };
  const handleOfferCheck = (event) => {
    setOffer(event.target.checked);
    setOfferError(false);
  };

  useEffect(() => {
    if (worker.telegram) {
      setContacts((oldArray) => [...oldArray, "telegram"]);
    }
    if (worker.whatsApp) {
      setContacts((oldArray) => [...oldArray, "whatsApp"]);
    }
    if (worker.viber) {
      setContacts((oldArray) => [...oldArray, "viber"]);
    }
    if (window.location.href.includes("history")) {
      // В истории скрывает кнопку и чекбокс
      setDisable(false);
    }
  }, []);

  const handleOrderCheck = (id) => {
    handleRecommendedWorkStatusChange(id);
  };

  const forceFileDownload = () => {
    const link = document.createElement("a");
    link.href = `${process.env.REACT_APP_BASEURL}/uploads/${orderPdf}`;
    link.setAttribute("target", "_blank");
    link.setAttribute("download", orderPdf); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  const getCardStyles = (type) => {
    const keys = Object.keys(styles).filter((key) => key.includes(type));
    const _styles = {};
    keys.forEach((key) => {
      _styles[key] = styles[key];
    });
    return _styles;
  };
  return (
    <Wrapper>
      <ModalConfirm
        currentCurrency={currentCurrency}
        visible={visible}
        toggle={toggle}
        price={price.toFixed(2)}
        orderNumber={orderNumber}
        customer={customer}
        isLoading={isLoading}
        error={error}
        setError={setError}
        handleCheckCode={handleCheckCode}
      />
      {!!toFixed?.length && (
        <>
          <CheckListTitle
            styles={titleStyles(styles, "mainTitle")}
            title={"Состав заказ-наряда"}
            isArrowActive={isAgreedOpen}
            onClick={() => setIsAgreedOpen(!isAgreedOpen)}
          />
          {isAgreedOpen &&
            toFixed?.map((work, index) => (
              <OrderItem
                currentCurrency={currentCurrency}
                styles={getCardStyles("action")}
                order={work}
                isVisibleIcons={true}
                isFixed={true}
                key={index}
              />
            ))}
        </>
      )}
      {notRelatedGoods?.length ? (
        <>
          <CheckListTitle
            styles={titleStyles(styles, "recommendation")}
            title={"Требует согласования"}
            isArrowActive={isNeedAgreedOpen}
            onClick={() => setIsNeedAgreedOpen(!isNeedAgreedOpen)}
          />
          {isNeedAgreedOpen &&
            notRelatedGoods.map((work, index) => (
              <OrderItem
                currentCurrency={currentCurrency}
                showControllers={showControllers}
                status={work.status}
                styles={getCardStyles("recommendation")}
                order={work}
                isVisibleIcons={true}
                isFixed={false}
                handleOrderCheck={handleOrderCheck}
                key={index}
              />
            ))}
        </>
      ) : null}
      {isRelatedGoods && (
        <RelatedProductsBlock
          background={styles.main_relatedProducts_background_color}
          border={styles.main_relatedProducts_background_border_color}
        >
          <GreyText>
            <RelatedGoodsLabel
              color={styles.main_relatedProducts_title_color}
              fontFamily={styles.main_relatedProducts_title_fontFamily}
              fontSize={styles.main_relatedProducts_title_fontSize}
              fontStyle={styles.main_relatedProducts_title_fontStyle}
              fontWeight={styles.main_relatedProducts_title_fontWeight}
              textDecoration={styles.main_relatedProducts_title_textDecoration}
            >
              Сопутствующие товары и услуги
            </RelatedGoodsLabel>
            {notConfirmedRecommendations
              .filter((el) => el.isRelatedGoods)
              .map((work, index) => (
                <CardRelatedProducts
                  currentCurrency={currentCurrency}
                  status={work.status}
                  styles={getCardStyles("relatedProducts")}
                  order={work}
                  checked={!work.deleted}
                  handleOrderCheck={handleOrderCheck}
                  showControllers={showControllers}
                  key={index}
                  isRelatedGoods={isRelatedGoods}
                  {...work}
                />
              ))}
          </GreyText>
        </RelatedProductsBlock>
      )}
      <PriceList>
        <PriceItem>
          <GreyText
            color={styles.main_total_relatedProducts_title_color}
            fontFamily={styles.main_total_relatedProducts_title_fontFamily}
            fontSize={styles.main_total_relatedProducts_title_fontSize}
            fontStyle={styles.main_total_relatedProducts_title_fontStyle}
            fontWeight={styles.main_total_relatedProducts_title_fontWeight}
            textDecoration={
              styles.main_total_relatedProducts_title_textDecoration
            }
          >
            Сопутствующие товары и услуги
          </GreyText>
          <GreyText
            className={"notranslate"}
            color={styles.main_total_relatedProducts_price_color}
            fontFamily={styles.main_total_relatedProducts_price_fontFamily}
            fontSize={styles.main_total_relatedProducts_price_fontSize}
            fontStyle={styles.main_total_relatedProducts_price_fontStyle}
            fontWeight={styles.main_total_relatedProducts_price_fontWeight}
            textDecoration={
              styles.main_total_relatedProducts_price_textDecoration
            }
          >
            {relatedGoodsPrice ? relatedGoodsPrice?.toFixed(2) : "0.00"}{" "}
            <span className="price">{currentCurrency}</span>
          </GreyText>
        </PriceItem>
        <PriceItem>
          <GreyText
            color={styles.main_total_recommendations_title_color}
            fontFamily={styles.main_total_recommendations_title_fontFamily}
            fontSize={styles.main_total_recommendations_title_fontSize}
            fontStyle={styles.main_total_recommendations_title_fontStyle}
            fontWeight={styles.main_total_recommendations_title_fontWeight}
            textDecoration={
              styles.main_total_recommendations_title_textDecoration
            }
          >
            Требует согласования
          </GreyText>
          <GreyText
            className={"notranslate"}
            color={styles.main_total_recommendations_price_color}
            fontFamily={styles.main_total_recommendations_price_fontFamily}
            fontSize={styles.main_total_recommendations_price_fontSize}
            fontStyle={styles.main_total_recommendations_price_fontStyle}
            fontWeight={styles.main_total_recommendations_price_fontWeight}
            textDecoration={
              styles.main_total_recommendations_price_textDecoration
            }
          >
            {recommendPrice.toFixed(2)}{" "}
            <span className="price">{currentCurrency}</span>
          </GreyText>
        </PriceItem>
        <PriceItem>
          <GreyText
            color={styles.main_total_actions_title_color}
            fontFamily={styles.main_total_actions_title_fontFamily}
            fontSize={styles.main_total_actions_title_fontSize}
            fontStyle={styles.main_total_actions_title_fontStyle}
            fontWeight={styles.main_total_actions_title_fontWeight}
            textDecoration={styles.main_total_actions_title_textDecoration}
          >
            Согласовано
          </GreyText>
          <GreyText
            className={"notranslate"}
            text={`1000.00 ${currentCurrency}`}
            color={styles.main_total_actions_price_color}
            fontFamily={styles.main_total_actions_price_fontFamily}
            fontSize={styles.main_total_actions_price_fontSize}
            fontStyle={styles.main_total_actions_price_fontStyle}
            fontWeight={styles.main_total_actions_price_fontWeight}
            textDecoration={styles.main_total_actions_price_textDecoration}
          >
            {" "}
            {fixedPrice.toFixed(2)}{" "}
            <span className="price">{currentCurrency}</span>
          </GreyText>
        </PriceItem>
        <PriceItem>
          <FinalPrice
            color={styles.main_total_title_color}
            fontFamily={styles.main_total_title_fontFamily}
            fontSize={styles.main_total_title_fontSize}
            fontStyle={styles.main_total_title_fontStyle}
            fontWeight={styles.main_total_title_fontWeight}
            textDecoration={styles.main_total_title_textDecoration}
          >
            Итого
          </FinalPrice>
          <FinalPrice
            className={"notranslate"}
            color={styles.main_total_price_color}
            fontFamily={styles.main_total_price_fontFamily}
            fontSize={styles.main_total_price_fontSize}
            fontStyle={styles.main_total_price_fontStyle}
            fontWeight={styles.main_total_price_fontWeight}
            textDecoration={styles.main_total_price_textDecoration}
          >
            {price.toFixed(2)} <span className="price">{currentCurrency}</span>
          </FinalPrice>
        </PriceItem>
      </PriceList>
      {orderPdf ? (
        <ButtonWrapper>
          <ConfirmButton
            style={{ padding: "10px 24px" }}
            buttonColor={styles.main_button_background_color}
            onClick={forceFileDownload}
          >
            <GreyText
              color={styles.main_button_color}
              fontFamily={styles.main_button_fontFamily}
              fontSize={styles.main_button_fontSize}
              fontStyle={styles.main_button_fontStyle}
              fontWeight={styles.main_button_fontWeight}
              textDecoration={styles.main_button_textDecoration}
            >
              Скачать детальный заказ-наряд
            </GreyText>
          </ConfirmButton>
        </ButtonWrapper>
      ) : null}
      <MasterInfo>
        <div style={{ position: "relative" }}>
          <ImgContainer
            src={
              worker.photo
                ? `${process.env.REACT_APP_BASEURL}/uploads/${worker?.photo?.path}`
                : require("../../assets/no-photo.jpg")
            }
          />
          {worker.signature ? (
            <Signature
              src={`${process.env.REACT_APP_BASEURL}/uploads/${worker?.signature?.path}`}
            />
          ) : null}
        </div>
        <MasterContacts>
          <ContactText
            color={styles.main_worker_info_role_color}
            fontFamily={styles.main_worker_info_role_fontFamily}
            fontSize={styles.main_worker_info_role_fontSize}
            fontStyle={styles.main_worker_info_role_fontStyle}
            fontWeight={styles.main_worker_info_role_fontWeight}
            textDecoration={styles.main_worker_info_role_textDecoration}
          >
            Контактное лицо
          </ContactText>
          <Contact>
            <ContactInfo
              color={styles.main_worker_name_color}
              fontFamily={styles.main_worker_name_fontFamily}
              fontSize={styles.main_worker_name_fontSize}
              fontStyle={styles.main_worker_name_fontStyle}
              fontWeight={styles.main_worker_name_fontWeight}
              textDecoration={styles.main_worker_name_textDecoration}
            >
              {worker.firstName} {worker.secondName}
            </ContactInfo>
          </Contact>
          <ContactInfo>
            <GreyText
              color={styles.main_worker_phone_color}
              fontFamily={styles.main_worker_phone_fontFamily}
              fontSize={styles.main_worker_phone_fontSize}
              fontStyle={styles.main_worker_phone_fontStyle}
              fontWeight={styles.main_worker_phone_fontWeight}
              textDecoration={styles.main_worker_phone_textDecoration}
            >
              {worker.phone}&nbsp;
            </GreyText>
          </ContactInfo>
          {(worker.telegram || worker.whatsApp || worker.viber) && (
            <ContactInfo>
              <GreyText
                color={styles.main_worker_messengers_color}
                fontFamily={styles.main_worker_messengers_fontFamily}
                fontSize={styles.main_worker_messengers_fontSize}
                fontStyle={styles.main_worker_messengers_fontStyle}
                fontWeight={styles.main_worker_messengers_fontWeight}
                textDecoration={styles.main_worker_messengers_textDecoration}
              >
                {"("}
              </GreyText>
              <GreyText
                color={styles.main_worker_messengers_color}
                fontFamily={styles.main_worker_messengers_fontFamily}
                fontSize={styles.main_worker_messengers_fontSize}
                fontStyle={styles.main_worker_messengers_fontStyle}
                fontWeight={styles.main_worker_messengers_fontWeight}
                textDecoration={styles.main_worker_messengers_textDecoration}
              >
                {contacts.join(",")}
              </GreyText>
              <GreyText
                color={styles.main_worker_messengers_color}
                fontFamily={styles.main_worker_messengers_fontFamily}
                fontSize={styles.main_worker_messengers_fontSize}
                fontStyle={styles.main_worker_messengers_fontStyle}
                fontWeight={styles.main_worker_messengers_fontWeight}
                textDecoration={styles.main_worker_messengers_textDecoration}
              >
                {")"}
              </GreyText>
            </ContactInfo>
          )}
        </MasterContacts>
      </MasterInfo>
      {showControllers && disable ? (
        <ButtonWrapper>
          <ConfirmButton
            buttonColor={styles.main_button_background_color}
            onClick={handleConfirmClick}
            disabled={!aggrement || !offer}
          >
            <GreyText
              color={styles.main_button_color}
              fontFamily={styles.main_button_fontFamily}
              fontSize={styles.main_button_fontSize}
              fontStyle={styles.main_button_fontStyle}
              fontWeight={styles.main_button_fontWeight}
              textDecoration={styles.main_button_textDecoration}
              isButton={true}
            >
              Согласовать
            </GreyText>
          </ConfirmButton>
        </ButtonWrapper>
      ) : null}
      {showControllers && disable ? (
        <AggrementWrapper>
          <Checkbox
            error={!aggrement}
            checked={aggrement}
            onChange={handleAggrementCheck}
            color={styles.main_agreement_checkbox_color}
            fill={styles.main_agreement_checkbox_fill_color}
          />
          <GreyText
            style={{
              width: "75%",
              marginLeft: "15px",
              textAlign: "left",
              fontSize: "12px",
            }}
            color={styles.main_agreement_text_color}
            fontFamily={styles.main_agreement_text_fontFamily}
            fontSize={styles.main_agreement_text_fontSize}
            fontStyle={styles.main_agreement_text_fontStyle}
            fontWeight={styles.main_agreement_text_fontWeight}
            textDecoration={styles.main_agreement_text_textDecoration}
          >
            Я подтверждаю предварительный заказ-наряд и даю согласие на
            обработку своих персональных данных.
          </GreyText>
        </AggrementWrapper>
      ) : null}

      {showControllers && contractOffer && (
        <AggrementWrapper>
          <Checkbox
            error={!offer}
            checked={offer}
            onChange={handleOfferCheck}
            color={styles.main_agreement_checkbox_color}
            fill={styles.main_agreement_checkbox_fill_color}
          />
          <GreyText
            style={{
              width: "75%",
              marginLeft: "15px",
              textAlign: "left",
              fontSize: "12px",
            }}
            color={styles.main_agreement_text_color}
            fontFamily={styles.main_agreement_text_fontFamily}
            fontSize={styles.main_agreement_text_fontSize}
            fontStyle={styles.main_agreement_text_fontStyle}
            fontWeight={styles.main_agreement_text_fontWeight}
            textDecoration={styles.main_agreement_text_textDecoration}
          >
            Нажимая кнопку Согласовать, соглашаюсь с условиями{" "}
            <Link href={FILES_URL + contractOffer.path} target="_blank">
              договора оферты
            </Link>
          </GreyText>
        </AggrementWrapper>
      )}
    </Wrapper>
  );
};

export default CheckList;
