import React, { useMemo } from 'react'
import ReactDOM from 'react-dom'
import classes from './ModalSuccess.module.css'
import { MODAL_TYPE_DIAGNOSTICS } from '../../utils/constants'

const Modal = ({ visible, toggle, text }) => {
  return visible
    ? ReactDOM.createPortal(
        <div className={classes.modal}>
          <div className={classes.modal__pop} role="dialog" aria-modal="true">
            <button
              className={classes.closeIcon}
              type="button"
              onClick={toggle}
            />
            <div className={classes.img_logo}></div>
            <image
              src={require('../../assets/hand.png')}
              alt="svg"
              style={{ width: '100%' }}
            />
            <div className={classes.modal__title}>Заявка отправлена!</div>
            <div className={classes.modal__desc}>{text}</div>
          </div>
          <div className={classes.modal__overlay}></div>
        </div>,
        document.body
      )
    : null
}

export default Modal
