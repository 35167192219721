import styled from "@emotion/styled";

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: #E5E5E5;
`
export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: #000;
  opacity: 0.75;
`;

export const Modal = styled.div`
  
`;
export const ModalPop = styled.div`
  background: #fff;
  border-radius: 10px;
  z-index: 999;
  width: 640px;
  min-width: 250px;
  margin: auto;
  padding: 80px 90px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 800px) {
      width: auto;
      padding: 40px 25px;
  }

  @media (max-width: 330px) {
    padding: 40px 25px;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  width: 22px;
  height: 22px;
  top: 22px;
  right: 22px;
  background-color: #fff;
  border: none;
  cursor: pointer;
  background-image: url('../../assets/closeIcon.svg');
`;
export const CodeWrapper = styled.div`
  width: 100%;
  background: #FFFFFF;
  padding: 50px;
  margin: 0 auto;
  margin-top: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);
  @media (max-width: 650px) {
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    max-width: 328px;
  }
`

export const OrderNumber = styled.span`
@media (max-width: 650px) {
    display:none;
  }
`
export const Title = styled.h3`
  margin: 0 auto;
  text-align: center;
  font-size: 22px;
  @media (max-width: 650px) {
    font-size: 17px;
  }
`
export const Price = styled.h3`
    color: #772AF3;
    text-align: center;
    margin: 8px auto 30px auto;
    font-size: 22px;
    font-weight: 500;
      @media (max-width: 650px) {
        font-size: 17px;
        text-align: center;
        margin: 7px auto 15px auto;
      }
`;
export const ErrorBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 13px;
  font-weight: 300;
  font-size: 17px;
  padding-top: 20px;
  text-align: center;
  color: #999999;
  @media (max-width: 650px) {
    font-size: 13px;
  }
`;
export const Number = styled.div`
    margin: 0 auto;
    width: 480px;
    font-weight: 400;
    font-size: 17px;
    text-align: center;
    padding-bottom: 40px;
  @media (max-width: 650px) {
    max-width: 254px;
    text-align: center;
  }
`

export const Phone = styled.div`
    font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Text = styled.div`
    font-weight: 600;
    font-size: 17px;
    width: 250px;
  margin: 0 auto;
    text-align: center;
    padding:20px 0;
  @media (max-width: 650px) {
    max-width: 254px;
    text-align: center;
  }
`
export const CodeContent = styled.div`
  text-align: center;
  padding-bottom: 70px;
`
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
`;
export const ConfirmButton = styled.button`
  width: 330px;
  background: #772af3;
  border-radius: 30px;
  font-weight: 400;
  color: #ffffff;
  font-size: 17px;
  padding: 15px 24px 15px 24px;
  border: none;
  cursor: pointer;
  @media (max-width: 650px) {
    width: 300px;
  }
  &:disabled {
    background: #C7C7C7;
    cursor: not-allowed;
  }
  &:focus {
    outline: none
  }
`;