import styled from '@emotion/styled'

export const Wrapper = styled.div`
  width: 100%;
  background: #e5e5e5;
`

export const OrderWrapper = styled.div`
  max-width: 780px;
  height: 100%;
  background: #ffffff;
  margin: 0 auto;
  margin-top: 80px;
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);
  @media (max-width: 650px) {
    margin-top: 0;
    margin-bottom: 0;
  }
`
export const Title = styled.h3``
export const CodeContent = styled.div``

export const HistoryTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  text-align: center;
  margin-top: 40px;
`
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -30px;
  margin-bottom: 30px;
`
export const ConfirmButton = styled.button`
  color: #772af3;
  width: 40%;
  background: ${props => (props.buttonColor ? props.buttonColor : '#EFEFEF')};
  border-radius: 30px;
  color: ${props => props.textColor};
  padding: 15px 24px 15px 24px;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
  }
`
