import React from "react";
import {Footer,Info,FooterInfo,Contacts,ImageContainer,Label,Link,Agreement} from './CustomFooter.styles'

const defaultStyles = {
    footer_dealer_color: "#999999",
};

export const CustomFooter = ({
    styles = defaultStyles,
    policy,agreement
}) => {
    const contacts = [
        "ООО \"Тизер\"",
        "344000 г. Ростов-на-Дону,",
        "пр. Михаила Нагибина,, д.14А, пом. 35",
        "ИНН/КПП 6161089547/616101001",
        "ОГРН 1196196044054",
        "Телефон: +7 988 585 50 36",
        "Электронная почта: sales@tizer-team.ru"
    ]

    return (
        <Footer color={styles.footer_background_color}>
                <FooterInfo>
                    <Contacts>
                        {contacts.map((contact,index) => {
                            return (
                                <Info key={index}>
                                    <Label
                                        color={styles.footer_contacts_color}
                                        fontFamily={styles.footer_contacts_fontFamily}
                                        fontSize={styles.footer_contacts_fontSize}
                                        fontStyle={styles.footer_contacts_fontStyle}
                                        fontWeight={styles.footer_contacts_fontWeight}
                                        textDecoration={styles.footer_contacts_textDecoration}
                                    >
                                        {contact}
                                    </Label>
                                </Info>
                            )
                        })}
                    </Contacts>
                    <Agreement>
                        <ImageContainer
                            maxSize={styles.logo_max_size || "85"}
                            src={
                                require("../../assets/tizer-logo.svg")
                            }
                        />
                        {policy && (
                            <Link href={"/privacy"} target="_blank" >
                                Политика конфиденциальности
                            </Link>
                        )}
                        {agreement && (
                            <Link href={"/agreement"} target="_blank" >
                                Пользовательское соглашение
                            </Link>
                        )}
                    </Agreement>
                </FooterInfo>
        </Footer>
    );
};

