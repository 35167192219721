import React, { useState } from 'react'
import { Global, css } from '@emotion/core'
import emotionNormalize from 'emotion-normalize'
import { Switch, Route } from 'wouter'
import { Layout } from './components'
import { ToastContainer } from './components/ToastContainer'
import {
  NotFound,
  PersonConfirmation,
  Order,
  MainPage,
  NotFoundOrder,
  SuccessOrder,
  Diagnostics,
  ConfirmationOrder, AgreementPage, PrivacyPage, ErrorPayment
} from './pages'
import SuccessPayment from './pages/SuccessPayment/SuccessPayment.jsx'
import CloseOrder from './pages/CloseOrder/CloseOrder'

export const AccordionContext = React.createContext()

function App() {
  const [toggleAll, toggleAllAccordion] = useState(false)
  return (
    <>
      <Global
        styles={css`
          ${emotionNormalize}
          html,
              body {
                padding: 0;
                margin: 0;
                min-height: 100%;
                font-family: Roboto, sans-serif;
                overflow-x: hidden;
              },
          `}
      />
      <AccordionContext.Provider value={[toggleAll, toggleAllAccordion]}>
        <Layout>
          <ToastContainer />
          <Switch>
            <Route path="/">
              <MainPage />
            </Route>
            <Route path="/confirmation">
              <PersonConfirmation />
            </Route>
            <Route path="/confirm">
              <ConfirmationOrder />
            </Route>
            <Route path="/error">
              <NotFoundOrder />
            </Route>
            <Route path="/success-payment">
              <SuccessPayment />
            </Route>
            <Route path="/error-payment">
              <ErrorPayment />
            </Route>
            <Route path="/close-order">
              <CloseOrder />
            </Route>
            <Route path="/success">
              <SuccessOrder />
            </Route>
            <Route path="/agreement">
              <AgreementPage />
            </Route>
            <Route path="/privacy">
              <PrivacyPage />
            </Route>
            <Route path="/c/:code">
              {(params) => <Diagnostics params={params} />}
            </Route>
            <Route path="/z/:code">
              {(params) => <Order params={params} />}
            </Route>
            <Route path="/:rest*">
              <NotFound />
            </Route>
          </Switch>
        </Layout>
      </AccordionContext.Provider>
    </>
  )
}

export default App
