import React, { useState, useMemo, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'wouter'
import request from '../../utils/api'
import {
  CodeWrapper,
  CodeContent,
  Title,
  ButtonWrapper,
  ConfirmButton,
  Wrapper,
} from './PersonConfirmation.styles'
import ReactCodeInput from 'react-code-input'
import './codeInput.css'
import { getParameterByName } from '../../utils/helpers'

const PersonConfirmation = () => {
  const [, setLocation] = useLocation()
  const [code, setCode] = useState('')
  const [validCode, setValidCode] = useState(true)
  const history = useHistory()
  const urlCode = useMemo(() => getParameterByName('code'))

  document.addEventListener('keypress', event => {
    if (event.which === 13) {
      handleClick()
    }
  })

  const handleClick = async () => {
    history.push(`/confirmation`, { from: 'confirmation' })
    if (!code) {
      setValidCode(false)
    } else if (code.length < 4) {
      setValidCode(false)
    } else {
      getCode(code)
    }
  }

  const handleCode = code => {
    setCode(code)
    setValidCode(true)
  }

  const getCode = async code => {
    try {
      window.location.replace(`/z/${code}`)
    } catch (e) {
      console.error(e)
      setLocation(`/error`)
    }
  }

  useEffect(() => {
    urlCode && getCode(urlCode)
  }, [urlCode])

  return (
    <>
      {!urlCode && (
        <Wrapper>
          <CodeWrapper>
            <Title>Введите код из СМС</Title>
            <CodeContent>
              <ReactCodeInput
                id="codeInput"
                type="text"
                fields={4}
                onChange={handleCode}
                isValid={validCode}
              />
              <ButtonWrapper>
                <ConfirmButton type="submit" onClick={handleClick}>
                  Подтвердить
                </ConfirmButton>
              </ButtonWrapper>
            </CodeContent>
          </CodeWrapper>
        </Wrapper>
      )}
    </>
  )
}

export default PersonConfirmation
